import React from "react";
import {
  Button,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import API from "../../helpers/api.jsx";
/* import Loader from "react-loader-spinner"; */
// import { members } from 'variables/general/members.jsx';
import moment from "moment";
import { Link } from "react-router-dom";
import { notify } from "../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
/* import { ExportToCsv } from "export-to-csv"; */
import ModalConfirmation from "../../components/modals/ModalConfirmation.jsx";
import ModalTags from "../../components/modals/ModalTags.jsx";
import ReactPaginate from "react-paginate";
var BASEDIR = process.env.REACT_APP_BASEDIR;
/* const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
};
const csvExporter = new ExportToCsv(options); */

class CourseManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      limit: 20,
      count: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      modalTags: false,
      modalBlock: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      courseToDelete: "",
      modalDelete: false,
      id: "",
      indexMute: "",
      indexRevoke: "",
      activated: false,
      tooltipOpen: false,
      modalConfirmAction: false,
      actionValue: false,
      selectedSessionId: "",
      idCourse: "",
      indexAction: "",
      text: "",
      role: "",
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      tags: [],
      isSearchResult: false,
      isFilterResult: false,
      filter: {
        gender: "",
        country: "",
        role: "",
        id: "",
      },
    };
  }

  getSearchedCourses2 = async (key) => {
    const limit = this.state.limit;
    const { filter } = this.state;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");

    if (this.checkProperties(filter)) {
      this.getCourses(1);

      return;
    } else {
      try {
        this.setState({
          loading: true,
          isSearchResult: true,
          searchQuery: this.state.filter.keyword,
        });
        const params = {
          content: { id: filter.id },

          access_token: accessToken,
          skip: (key - 1) * this.state.limit,
          limit: limit,
        };

        const courses = await API.get(`Courses/filterBy`, { params: params });
        console.log("courses", courses);
        if (courses.status === 200) {
          const pageCount = Math.ceil(courses.data.count / this.state.limit);
          this.setState({
            count: pageCount,
            totalCount: courses.data.count,
            courses: courses.data.data,
          });
          this.setState(
            {
              active: key,
              loading: false,
              courses: courses.data.data,
            },
            () => {
              console.log(this.state);
            }
          );
          this.setState();
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };
  async componentWillMount() {
    await this.getCourses(this.state.active);
    console.log("hello", this.state.courses);
    /* this.getCount();
    
    console.log("my role", this.state.userProfile.role); */
  }

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleGendersInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          gender: target.value,
        },
      },
      () => this.getFilteredCourses(1)
    );
  };
  handleGovernorateInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          country: target.value,
        },
      },
      () => this.getFilteredCourses(1)
    );
  };
  handleRolesInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          role: target.value,
        },
      },
      () => this.getFilteredCourses(1)
    );
  };

  /* toggleTooltip=(target)=> {

    this.setState({
      [target]: !this.state[target]
    });
  } */

  //Export DATA

  /* exportData = async () => {
    if (this.state.text === "") {
      const courses = await axios.get(
        `${SERVERDIR}Courses/export?type=members&all=true&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (courses.status === 200) {
        csvExporter.generateCsv(courses.data);
      } else {
        return;
      }
    } else {
      const filters = encodeURIComponent(
        JSON.stringify({ text: this.state.text })
      );
      const courses = await axios.get(
        `${SERVERDIR}Courses/export?type=members&all=true&filters=${filters}&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (courses.status === 200) {
        csvExporter.generateCsv(courses.data);
      } else {
        return;
      }
    }
  }; */

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getCourses = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        isFilterResult: false,
      });
      const courses = await API.get(
        `Courses/listCourses?limit=${limit}&skip=${skip}&access_token=${accessToken}`
      );

      if (courses.status === 200) {
        const pageCount = Math.ceil(courses.data.count / this.state.limit);
        /* this.setState({ count: pageCount }); */

        this.setState({
          count: pageCount,
          csvData: courses.data.courses,
          active: key,
          loading: false,
          courses: courses.data.courses,
        });
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };

  cancelSearch = () => {
    this.setState({ id: "", active: 1, filter: { id: "" } }, () =>
      window.history.pushState(
        null,
        null,
        BASEDIR + `/admin/courseManagement/${1}`
      )
    );
    this.getCourses(1);
  };

  ///Search
  getSearchedCourses = async (key) => {
    const limit = this.state.limit;
    const text = this.state.text;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    if (text === "") {
      this.getCourses(1);
      return;
    } else {
      try {
        this.setState({
          loading: true,
          isSearchResult: true,
          filter: {
            gender: "",
            country: "",
            role: "",
          },
        });
        const courses = await API.get(
          `Courses/search?text=${text}&skip=${
            (key - 1) * limit
          }&limit=${limit}&access_token=${accessToken}`
        );

        if (courses.status === 200) {
          const pageCount = Math.ceil(courses.data.count / this.state.limit);
          this.setState({ count: pageCount });
          console.log("courses searched content :", courses.data.courses);

          this.setState(
            {
              csvData: courses.data.courses,
              active: key,
              loading: false,
              courses: courses.data.courses,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };
  ///Filter
  getFilteredCourses = async (key) => {
    const limit = this.state.limit;
    const { filter } = this.state;
    var search = encodeURIComponent(JSON.stringify(filter));
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    if (
      this.checkProperties(filter) ||
      filter.country === "Governorate" ||
      filter.gender === "All Users" ||
      filter.role === "Role"
    ) {
      if (filter.country === "Governorate") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              country: "",
            },
          },
          () => {
            this.getFilteredCourses(1);
            return;
          }
        );
      }
      if (filter.gender === "All Users") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              gender: "",
            },
          },
          () => {
            this.getFilteredCourses(1);
            return;
          }
        );
      }
      if (filter.role === "Role") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              role: "",
            },
          },
          () => {
            this.getFilteredCourses(1);
            return;
          }
        );
      } else {
        this.getCourses(1);
        return;
      }
    } else {
      try {
        this.setState({
          loading: true,
          isFilterResult: true,
          text: "",
        });
        const courses = await API.get(
          `Courses/searchBy?text=${search}&skip=${
            (key - 1) * limit
          }&limit=${limit}&access_token=${accessToken}`
        );

        if (courses.status === 200) {
          const pageCount = Math.ceil(courses.data.count / this.state.limit);
          this.setState({ count: pageCount });
          console.log("courses searched content :", courses.data.courses);

          this.setState(
            {
              csvData: courses.data.courses,
              active: key,
              loading: false,
              courses: courses.data.courses,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };

  handleSearchInput = async (e) => {
    const target = e.target;
    console.log(target.value);
    this.setState(
      {
        filter: {
          ...this.state.filter,
          id: target.value,
        },
      },
      console.log(this.state)
    );
  };

  toggleModalTags = (e, tags) => {
    this.setState({
      modalTags: !this.state.modalTags,
      tags: tags,
    });
  };
  closeModalTags = () => {
    this.setState({
      modalTags: !this.state.modalTags,
    });
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.text) {
      this.getSearchedCourses2(selectedPage + 1);
    } else if (this.state.isFilterResult) {
      this.getFilteredCourses(selectedPage + 1);
    } else {
      this.getCourses(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/admin/courseManagement/${selectedPage + 1}`
    );
  };

  handleLevelColor = (level) => {
    if (level && level.toUpperCase() === "BEGINNER") {
      return "#00BFA5";
    } else if (level && level.toUpperCase() === "INTERMEDIATE") {
      return "#FF8A65";
    } else if (level && level.toUpperCase() === "ADVANCED") {
      return "#E6413D";
    } else {
      return "#000";
    }
  };

  openModalConfirmAction = (id, value, status) => {
    if (status === "Draft") {
      this.setState({
        modalConfirmAction: !this.state.modalConfirmAction,
        actionValue: value,
        selectedSessionId: id,
      });
    } else return null;
  };
  toggleModalConfirmAction = () => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction,
    });
  };
  handleStatusChange = async () => {
    console.log(this.state.id);
    const selectedSessionId = this.state.selectedSessionId;
    const value = this.state.actionValue;
    try {
      let result = await API.post(`Courses/acceptRefuse`, null, {
        params: {
          courseId: selectedSessionId,
          value: value,
          access_token: localStorage.getItem("WeCodeLand_jwt"),
        },
      });
      if (result.status === 200) {
        console.log("Action done SUCCESSFULLY");
        notify(
          {
            type: "success",
            message: value ? "Course Accepted" : "Course Refused",
          },
          this
        );
        this.getCourses(this.state.active);
        this.setState({
          loading: false,
          modalConfirmAction: !this.state.modalConfirmAction,
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      await this.setState({
        loading: false,
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  };
  openModalDelete = (id) => {
    this.setState({
      courseToDelete: id,
      modalDelete: !this.state.modalDelete,
    });
  };
  toggleModalDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
    });
  };
  handleDeleteCourse = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const { courses, courseToDelete } = this.state;

    this.setState({
      loading: true,
    });

    try {
      // delete course
      const deletedCourse = await API.patch(
        `Courses/deleteCourse?access_token=${accessToken}&courseId=${courseToDelete}`
      );

      if (deletedCourse.status === 200) {
        notify({ type: "success", message: "Course deleted" }, this);
        this.setState({ modalDelete: false });
        this.getCourses(this.state.active);
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      this.setState({
        loading: false,
      });
      notify({ type: "danger", message: message }, this);
      console.log("error: ", error);
    }
  };

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const { courses, tags, revoked } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Course Management </h1>
                </div>
                <div className="float-right">
                  <h1>
                    <Link to={`${BASEDIR}/admin/newCourse`}>
                      <Button id="add-button">
                        <i className="fa fa-plus"></i>&nbsp; Add new Course
                      </Button>
                    </Link>
                  </h1>
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.getSearchedCourses2(1);
                    }}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={() => this.getSearchedCourses2(1)}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        className="search-input"
                        placeholder={"Search By Id..."}
                        value={this.state.filter.id}
                        onChange={this.handleSearchInput}
                      />
                      <span
                        onClick={this.cancelSearch}
                        style={{
                          display:
                            this.state.searchQuery !== "" ? "block" : "none",
                        }}
                        className="fa fa-trash cancel-search"
                      ></span>
                    </InputGroup>
                  </form>
                </div>
                {/* <div className="float-right" style={{ marginTop: "2%" }}>
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {e.preventDefault(); this.getSearchedCourses(1)}}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={() => this.getSearchedCourses(1)}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        style={{ position: "relative" }}
                        placeholder={"Search..."}
                        value={this.state.text}
                        name="text"
                        onChange={this.handleInputChange}
                      />
                      <span
                        onClick={this.cancelSearch}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "8px",
                          top: "12px",
                          zIndex: "100",
                          display: this.state.text !== "" ? "block" : "none"
                        }}
                        class="fa fa-trash"
                      ></span>
                    </InputGroup>
                  </form>
                </div> */}
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        {/* <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span> */}
                        {this.state.isSearchResult ||
                        this.state.isFilterResult ? (
                          <span className="results-count">
                            {this.state.count} results were found
                          </span>
                        ) : null}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              <th className="fullName">Course Name</th>
                              {/* <th>
                                <select
                                  className="fullName filter-col"
                                  id="inputGender"
                                  name="gender"
                                  onChange={this.handleGendersInputChange}
                                  value={this.state.filter.gender}
                                  required
                                >
                                  {genders.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th> */}
                              <th className="centered-column-text">Tutor</th>
                              <th className="centered-column-text"></th>
                              <th className="centered-column-text">Level</th>
                              <th className="centered-column-text">Id</th>
                              <th className="centered-column-text">Added On</th>
                              <th className="centered-column-text">Domain</th>
                              <th className="centered-column-text">Price</th>
                              <th className="centered-column-text">Offer</th>
                              <th className="centered-column-text">Status</th>
                              <th className="centered-column-text">Edit</th>
                              <th className="centered-column-text">Delete</th>
                              {/* <th>
                                <select
                                  className="centered-column-text filter-col"
                                  id="inputRole"
                                  name="role"
                                  onChange={this.handleRolesInputChange}
                                  value={this.state.filter.role}
                                  required
                                >
                                  {roles.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th> */}
                              <th className="centered-column-text">
                                Accept/Refuse
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {courses &&
                              courses.length > 0 &&
                              courses.map((course, key) => {
                                return (
                                  <tr key={key}>
                                    <td
                                      className="text-cell" /* id={`Tooltip${key}`} */
                                    >
                                      {course && course.title}
                                    </td>
                                    <td className="text-cell">
                                      {course &&
                                        course.tutors &&
                                        course.tutors &&
                                        course.tutors.fullName}
                                    </td>
                                    <td>
                                      {course &&
                                      course.tutors &&
                                      course.tutors &&
                                      course.tutors.fullName ? (
                                        <i className="fa fa-info clickable purple"></i>
                                      ) : null}
                                    </td>{" "}
                                    <td
                                      className="centered-column-text bold"
                                      style={{
                                        color: this.handleLevelColor(
                                          course && course.difficulty
                                        ),
                                      }}
                                    >
                                      {course && course.difficulty}
                                    </td>{" "}
                                    <td>{course && course.id}</td>
                                    <td className="centered-column-text">
                                      {moment(course.createdAt).format(
                                        "D MMM YYYY"
                                      )}
                                    </td>
                                    <td className="centered-column-text">
                                      <span
                                        className="purple clickable"
                                        onClick={(e) =>
                                          this.toggleModalTags(
                                            e,
                                            course &&
                                              course.domains &&
                                              course.domains
                                          )
                                        }
                                      >
                                        {course &&
                                          course.domains &&
                                          course.domains.length}{" "}
                                        Tags
                                      </span>
                                    </td>
                                    <td className="centered-column-text">
                                      {course && course.price}
                                    </td>
                                    <td className="centered-column-text">
                                      {course && course.discount ? (
                                        <span>
                                          {course && course.discount}%
                                        </span>
                                      ) : null}
                                    </td>
                                    <td className="centered-column-text">
                                      {course && course.deleted === true
                                        ? "Deleted"
                                        : course && course.status}
                                    </td>
                                    <td className="centered-column-text">
                                      <span
                                        className="purple clickable"
                                        onClick={
                                          course && course.deleted !== true
                                            ? () => {
                                                this.props.history.push(
                                                  `${BASEDIR}/admin/editCourse/${
                                                    course && course.id
                                                  }`
                                                );
                                              }
                                            : null
                                        }
                                      >
                                        Edit
                                      </span>
                                    </td>
                                    <td className="centered-column-text">
                                      {course && course.deleted !== true ? (
                                        <i
                                          className="clickable fa fa-trash-o text-danger"
                                          style={{ fontSize: "17px" }}
                                          onClick={
                                            course && course.deleted !== true
                                              ? () => {
                                                  this.openModalDelete(
                                                    course && course.id
                                                  );
                                                }
                                              : null
                                          }
                                        ></i>
                                      ) : null}
                                    </td>
                                    <td className="centered-column-text">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            course && course.status === "Draft"
                                              ? "green"
                                              : "#CFCFCF",
                                          padding: "5px",
                                        }}
                                        onClick={() => {
                                          this.openModalConfirmAction(
                                            course.id,
                                            true,
                                            course.status
                                          );
                                        }}
                                      >
                                        <i className="fa fa-check"></i>&nbsp;
                                      </span>
                                      &nbsp;
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color:
                                            course && course.status === "Draft"
                                              ? "red"
                                              : "#CFCFCF",
                                          padding: "5px",
                                        }}
                                        onClick={() => {
                                          this.openModalConfirmAction(
                                            course.id,
                                            false,
                                            course.status
                                          );
                                        }}
                                      >
                                        <i className="fa fa-times"></i>&nbsp;
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            {((courses && courses.length === 0) ||
                              !courses) && (
                              <tr className="text-center">
                                <td colSpan="10">
                                  <b>Oops! no data found</b>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>

        <div>
          <ModalConfirmation
            isOpen={this.state.modalConfirmAction}
            toggle={this.toggleModalConfirmAction}
            disabledButton={false}
            confirm={this.handleStatusChange}
            cancel={this.toggleModalConfirmAction}
            title={this.state.actionValue ? "Accept course" : "Refuse course"}
            body={
              this.state.actionValue
                ? "Are you sure you want to accept this course?"
                : "Are you sure you want to refuse this course?"
            }
            confirmText={this.state.actionValue ? "Accept" : "Refuse"}
          />
        </div>
        <div>
          <ModalTags
            isOpen={this.state.modalTags}
            toggle={this.toggleModalTags}
            close={this.closeModalTags}
            title="Domain"
            tags={tags}
          />
        </div>
        <ModalConfirmation
          isOpen={this.state.modalDelete}
          toggle={this.toggleModalDelete}
          disabledButton={false}
          confirm={this.handleDeleteCourse}
          cancel={this.toggleModalDelete}
          title={"Delete course"}
          body={
            "Are you sure you want to delete this course? This process cannot be undone"
          }
          confirmText={"Confirm"}
        />
      </div>
    );
  }
}

export default CourseManagement;
