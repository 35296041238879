import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "./../../helpers/common.jsx";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import StarRatingComponent from "react-star-rating-component";
import API from "../../helpers/api.jsx";
import { notify } from "../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";

class ModalRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionRating: 3.5,
      tutorRating: 3.5,
      roomRating: 3.5,
      isSessionInputExpended: false,
      sessionReview: "",
      isTutorInputExpended: false,
      tutorReview: "",
      isRoomInputExpended: false,
      roomReview: "",
    };
  }
  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  };
  cancelSessionReviewInput = () => {
    this.setState({
      isSessionInputExpended: false,
      sessionReview: "",
    });
  };
  expandSessionReviewInput = () => {
    this.setState({
      isSessionInputExpended: true,
    });
  };
  cancelTutorReviewInput = () => {
    this.setState({
      isTutorInputExpended: false,
      tutorReview: "",
    });
  };
  expandTutorReviewInput = () => {
    this.setState({
      isTutorInputExpended: true,
    });
  };
  cancelRoomReviewInput = () => {
    this.setState({
      isRoomInputExpended: false,
      roomReview: "",
    });
  };
  expandRoomReviewInput = () => {
    this.setState({
      isRoomInputExpended: true,
    });
  };
  onSessionStarClick = (nextValue, prevValue, name, e) => {
    const xPos =
      (e.pageX - e.currentTarget.getBoundingClientRect().left) /
      e.currentTarget.offsetWidth;

    if (xPos <= 0.5) {
      nextValue -= 0.5;
    }

    console.log(
      "name: %s, nextValue: %s, prevValue: %s",
      name,
      nextValue,
      prevValue
    );
    // console.log(e);
    this.setState({ sessionRating: nextValue });
  };
  onTutorStarClick = (nextValue, prevValue, name, e) => {
    const xPos =
      (e.pageX - e.currentTarget.getBoundingClientRect().left) /
      e.currentTarget.offsetWidth;

    if (xPos <= 0.5) {
      nextValue -= 0.5;
    }

    console.log(
      "name: %s, nextValue: %s, prevValue: %s",
      name,
      nextValue,
      prevValue
    );
    // console.log(e);
    this.setState({ tutorRating: nextValue });
  };
  onRoomStarClick = (nextValue, prevValue, name, e) => {
    const xPos =
      (e.pageX - e.currentTarget.getBoundingClientRect().left) /
      e.currentTarget.offsetWidth;

    if (xPos <= 0.5) {
      nextValue -= 0.5;
    }

    console.log(
      "name: %s, nextValue: %s, prevValue: %s",
      name,
      nextValue,
      prevValue
    );
    // console.log(e);
    this.setState({ roomRating: nextValue });
  };
  submit = async () => {
    const profileId = localStorage.getItem("WeCodeLand_user");
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const request = {
      profileId: profileId,
      sessionId: this.props.session.id,
      sStars: this.state.sessionRating,
      tStars: this.state.tutorRating,
      rStars: this.state.roomRating,
    };
    if (this.state.sessionReview.length > 0) {
      request.feedSession = this.state.sessionReview;
    }
    if (this.state.tutorReview.length > 0) {
      request.feedTeacher = this.state.tutorReview;
    }
    if (this.state.roomReview.length > 0) {
      request.feedRoom = this.state.roomReview;
    }
    let result = await (`Profiles/giveNote`,
    request,
    {
      params: { access_token: accessToken },
    });
    if (result.status === 204) {
      this.props.toggle();
      this.props.onRatingDone();
      notify({ type: "success", message: "Thank you for your feedback" }, this);
    } else {
      notify({ type: "danger", message: "Somthing went wrong" }, this);
    }
  };

  render() {
    const { session } = this.props;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
          <ModalHeader>Give us your feedback</ModalHeader>
          <ModalBody>
            <div>
              <div className="form-group">
                <label className="form-label bold" htmlFor="field-1">
                  Rating the wecode experience
                </label>
                <br />
                <label>
                  Since you’ve just attended{" "}
                  <span className="bold clickable purple">
                    {session && session.title}
                  </span>{" "}
                  we would like you to give us feedback about the whole
                  experience.
                </label>
                <br />
                <label className="form-label bold" htmlFor="field-1">
                  Rate the session
                </label>
                <br />
                <label>
                  The session is{" "}
                  <span className="bold clickable purple">
                    {session && session.title}
                  </span>
                </label>
                <br />
                <div style={{ fontSize: 30 }}>
                  <StarRatingComponent
                    name="app6"
                    starColor="#D43272"
                    emptyStarColor="#D43272"
                    value={this.state.sessionRating}
                    onStarClick={this.onSessionStarClick}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i
                            className={
                              index <= value ? "fa fa-star" : "fa fa-star-o"
                            }
                          />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: "absolute" }}>
                            <i className="fa fa-star-o" />
                          </span>
                          <span>
                            <i className="fa fa-star-half-o" />
                          </span>
                        </span>
                      );
                    }}
                  />
                </div>
                <br />
                <span
                  className="clickable purple"
                  onClick={
                    this.state.isSessionInputExpended
                      ? () => this.cancelSessionReviewInput()
                      : () => this.expandSessionReviewInput()
                  }
                >
                  {this.state.isSessionInputExpended
                    ? "Delete Review"
                    : "Add a review to this session"}
                </span>
                {this.state.isSessionInputExpended ? (
                  <Input
                    type="textarea"
                    rows="5"
                    maxLength="350"
                    cols="50"
                    className="form-control custom-fixed-textarea"
                    name="sessionReview"
                    value={this.state.sessionReview}
                    onChange={this.updateInputValue}
                    placeholder="Give a review to this session"
                  />
                ) : null}
                <br />
                <label className="form-label bold" htmlFor="field-1">
                  Rate the tutor
                </label>
                <br />
                <label>
                  The tutor of this session is{" "}
                  <span className="bold clickable purple">
                    {session && session.tutors && session.tutors.fullName}
                  </span>
                </label>
                <br />
                <div style={{ fontSize: 30 }}>
                  <StarRatingComponent
                    name="app6"
                    starColor="#D43272"
                    emptyStarColor="#D43272"
                    value={this.state.tutorRating}
                    onStarClick={this.onTutorStarClick}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i
                            className={
                              index <= value ? "fa fa-star" : "fa fa-star-o"
                            }
                          />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: "absolute" }}>
                            <i className="fa fa-star-o" />
                          </span>
                          <span>
                            <i className="fa fa-star-half-o" />
                          </span>
                        </span>
                      );
                    }}
                  />
                </div>
                <br />
                <span
                  className="clickable purple"
                  onClick={
                    this.state.isTutorInputExpended
                      ? () => this.cancelTutorReviewInput()
                      : () => this.expandTutorReviewInput()
                  }
                >
                  {this.state.isTutorInputExpended
                    ? "Delete Review"
                    : "Add a review to this tutor"}
                </span>
                {this.state.isTutorInputExpended ? (
                  <Input
                    type="textarea"
                    rows="5"
                    maxLength="350"
                    cols="50"
                    className="form-control custom-fixed-textarea"
                    name="tutorReview"
                    value={this.state.tutorReview}
                    onChange={this.updateInputValue}
                    placeholder="Give a review to this tutor"
                  />
                ) : null}
                <br />
                <label className="form-label bold" htmlFor="field-1">
                  Rate the classroom
                </label>
                <br />
                <label>
                  The session was taught in{" "}
                  <span className="bold clickable purple">
                    {session &&
                      session.sessionBooking &&
                      session.sessionBooking.length > 0 &&
                      session.sessionBooking[0] &&
                      session.sessionBooking[0].bookingRoom &&
                      session.sessionBooking[0].bookingRoom.name}
                  </span>
                </label>
                <br />
                <div style={{ fontSize: 30 }}>
                  <StarRatingComponent
                    name="app6"
                    starColor="#D43272"
                    emptyStarColor="#D43272"
                    value={this.state.roomRating}
                    onStarClick={this.onRoomStarClick}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i
                            className={
                              index <= value ? "fa fa-star" : "fa fa-star-o"
                            }
                          />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: "absolute" }}>
                            <i className="fa fa-star-o" />
                          </span>
                          <span>
                            <i className="fa fa-star-half-o" />
                          </span>
                        </span>
                      );
                    }}
                  />
                </div>
                <br />
                <span
                  className="clickable purple"
                  onClick={
                    this.state.isRoomInputExpended
                      ? () => this.cancelRoomReviewInput()
                      : () => this.expandRoomReviewInput()
                  }
                >
                  {this.state.isRoomInputExpended
                    ? "Delete Review"
                    : "Add a review to this classroom"}
                </span>
                {this.state.isRoomInputExpended ? (
                  <Input
                    type="textarea"
                    rows="5"
                    maxLength="350"
                    cols="50"
                    className="form-control custom-fixed-textarea"
                    name="roomReview"
                    value={this.state.roomReview}
                    onChange={this.updateInputValue}
                    placeholder="Give a review to this classroom"
                  />
                ) : null}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              onClick={this.submit}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
ModalRating.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  submit: PropTypes.func,
  session: PropTypes.object,
};
export default (props) => (
  <AppContext.Consumer>
    {(Helper) => <ModalRating {...props} helper={Helper} />}
  </AppContext.Consumer>
);
