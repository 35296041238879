/** @format */

import React, { useState, useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import ReactPaginate from "react-paginate";
import { Multiselect } from "multiselect-react-dropdown";
import { InterestsList, CountriesList } from "../../variables/blog/pages";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import {
	Button,
	Col,
	Input,
	InputGroup,
	InputGroupAddon,
	Row,
	Table,
} from "reactstrap";
import ModalProfilePreview from "../../components/modals/ModalProfilePreview.jsx";
import API from "./../../helpers/api.jsx";
import Loader from "react-loader-spinner";
import moment from "moment";

const BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

export default function EditUser({ match, history }) {
	const [loading, setloading] = useState(false);
	const [rolesList, setRolesList] = useState([]);
	const [roleId, setRoleId] = useState("");
	const [profileData, setProfileData] = useState({});
	const [user, setUser] = useState({
		fullName: "",
		email: "",
		password: "",
		phone: "",
		country: "",
		birthday: moment().format("YYYY-MM-DD"),
		governorate: "",
	});

	const [interest, setInterest] = useState([]);
	const multiselectRef = React.createRef();

	useEffect(() => {
		getProfile();
		getRoles();
	}, []);

	const updateInputValue = e => {
		setUser({
			...user,
			[e.target.name]: e.target.value,
		});
	};
	const updateCoutryValue = e => {
		setUser({
			...user,
			country: e,
		});
	};
	const updateGovernerateValue = e => {
		setUser({
			...user,
			governorate: e,
		});
	};
	useEffect(() => {
		console.log(user);
	}, [user]);
	const getRoles = async () => {
		const accessToken = localStorage.getItem("WeCodeLand_jwt");
		const roles = await API.get(
			`Profiles/adminsList?access_token=${accessToken}`
		);

		if (roles.status === 200) {
			setRolesList(roles.data);
		} else {
			console.log("error");
		}
	};

	const updateSelectedInterest = () => {
		const names = multiselectRef.current.getSelectedItems();

		setInterest(names);
	};
	const getProfile = async () => {
		const accessToken = localStorage.getItem("WeCodeLand_jwt");
		const profile = await API.get(`Profiles/getUser`, {
			params: { userId: match.params.id, access_token: accessToken },
		});

		if (profile.status === 200) {
			const {
				fullName,
				email,
				password,
				phone,
				country,
				birthday,
				governorate,
			} = profile.data?.profileInfos;
			setProfileData(profile.data?.profileInfos);
			setUser({
				...user,
				fullName,
				email,
				password,
				phone,
				country,
				birthday: moment(birthday).format("YYYY-MM-DD"),
				governorate,
			});
			setInterest(
				profile.data?.profileInfos.interest.map((str, index) => ({
					name: str,
				}))
			);
			if (
				profile.data?.profileInfos?.roles?.some(el =>
					el?.name?.toUpperCase()?.includes("ADMIN")
				)
			) {
				setRoleId(
					profile.data?.profileInfos?.roles?.filter(el =>
						el?.name?.toUpperCase()?.includes("ADMIN")
					)?.[0]?.id
				);
			}
		} else {
			console.log("error");
		}
	};

	const updateUser = e => {
		e.preventDefault();
		const { fullName, email, password, phone, country } = user;
		const request = {
			...user,
			interest: interest.map(function (item) {
				return item["name"];
			}),
		};
		if (
			profileData?.roles?.some(el => el?.name?.toUpperCase()?.includes("ADMIN"))
		) {
			request.userId = match?.params?.id;
			if (roleId !== "") {
				request.roleId = rolesList?.filter(el => el?.id === roleId)?.[0]?.id;
			}
		}
		API.post(
			profileData?.roles?.some(el => el?.name?.toUpperCase()?.includes("ADMIN"))
				? "Profiles/editUser"
				: `Profiles/updateProfile/${match?.params?.id}`,
			request,
			{
				params: { access_token: localStorage.getItem("WeCodeLand_jwt") },
			}
		)
			.then(res => {
				setloading(false);
				if (res.status === 200) {
					console.log("SUBMITTED SUCCESSFULLY");
					history.push(BASEDIR + "/admin/membersManagement");
				}
			})
			.catch(error => {
				// this.setState({
				//   loading: false
				// });
				console.log("error:", error);
				const {
					response: {
						data: {
							error: { message },
						},
					} = {},
				} = error;
			});
	};

	return (
		<div>
			<div className='content'>
				<Row>
					<Col xs={12} md={12}>
						<div className='page-title'>
							<div className='float-left'>
								<h1 className='title' style={{ textTransform: "none" }}>
									Edit User
								</h1>
							</div>
						</div>

						<div className='row margin-0'>
							<div className='col-12'>
								<section className='box '>
									<header className='panel_header'></header>
									<div className='content-body'>
										<form id='myForm' onSubmit={updateUser} method='post'>
											<div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
												<div className='row'>
													{profileData?.roles?.some(el =>
														el?.name?.toUpperCase()?.includes("ADMIN")
													) && (
														<div className='form-group col-md-6'>
															<label
																htmlFor='imputRoleId'
																style={{ fontWeight: "bold" }}>
																Role
															</label>
															<select
																id='imputRoleId'
																name='roleId'
																className='form-control'
																style={{ borderRadius: "4px" }}
																onChange={e => setRoleId(e.target.value)}
																value={roleId}
																placeholder='Role'
																required>
																{rolesList?.map(({ id, name }) => {
																	return (
																		<option value={id} key={id}>
																			{name}
																		</option>
																	);
																})}
															</select>
														</div>
													)}
													<div className='form-group col-md-6'>
														<label
															htmlFor='imputFullName'
															style={{ fontWeight: "bold" }}>
															FullName
														</label>
														<input
															type='text'
															name='fullName'
															maxLength='400'
															onChange={updateInputValue}
															value={user?.fullName}
															className='form-control'
															id='imputFullName'
															placeholder='Created by'
															style={{ borderRadius: "4px" }}
															required
														/>
													</div>
												</div>
												<div className='row'>
													<div className='form-group col-md-6'>
														<label
															htmlFor='imputEmail'
															style={{ fontWeight: "bold" }}>
															Email
														</label>
														<input
															type='email'
															className='form-control'
															id='user_email'
															name='email'
															value={user?.email}
															onChange={updateInputValue}
															required
														/>
													</div>
													<div className='form-group col-md-6'>
														<label
															htmlFor='imputPassword'
															style={{ fontWeight: "bold" }}>
															Passsword
														</label>
														<input
															type='password'
															name='password'
															maxLength='30'
															onChange={updateInputValue}
															value={user?.password}
															className='form-control'
															id='imputPassword'
															placeholder='Password'
															style={{ borderRadius: "4px" }}
														/>
													</div>
												</div>
												<div className='row'>
													<div className='form-group col-md-6'>
														<label
															htmlFor='imputPhone'
															style={{ fontWeight: "bold" }}>
															Phone
														</label>
														<input
															type='phone'
															className='form-control'
															id='user_phone'
															name='phone'
															value={user?.phone}
															onChange={updateInputValue}
															required
														/>
													</div>{" "}
													<div className='form-group col-md-6'>
														<label
															htmlFor='inputBirthday'
															style={{ fontWeight: "bold" }}>
															birthday
														</label>
														<input
															type='date'
															className='form-control'
															id='user_birthday'
															name='birthday'
															value={user?.birthday}
															defaultValue={user?.birthday}
															onChange={updateInputValue}
															required
														/>
													</div>
												</div>
												<div className='row'>
													<div className='form-group col-md-6'>
														<label
															htmlFor='inputCountry'
															style={{ fontWeight: "bold" }}>
															Country
														</label>

														<CountryDropdown
															id='inputCountry'
															name='country'
															onChange={updateCoutryValue}
															value={user?.country}
															className='form-control'
														/>
													</div>
													<div className='form-group col-md-6'>
														<label
															htmlFor='inputGouvernerates'
															style={{ fontWeight: "bold" }}>
															Governorate
														</label>
														<RegionDropdown
															country={user.country}
															value={user?.governorate}
															onChange={updateGovernerateValue}
															className='form-control'
															name='governorate'
															id='inputGovernorate'
														/>
													</div>
												</div>
												<div className='row'>
													<div className='form-group col-md-6'>
														<label
															htmlFor='inputIntrest'
															style={{ fontWeight: "bold" }}>
															Interests/Expertises
														</label>
														<Multiselect
															id='inputInterest'
															ref={multiselectRef}
															name='interest'
															selectedValues={interest}
															options={InterestsList} // Options to display in the dropdown
															onSelect={updateSelectedInterest} // Function will trigger on select event
															onRemove={updateSelectedInterest} // Function will trigger on remove event
															displayValue='name' // Property name to display in the dropdown options
														/>
													</div>
												</div>

												<div className='row' style={{ justifyContent: "end" }}>
													<Button
														form='myForm'
														key='submit'
														htmltype='submit'
														style={{ borderRadius: "8px" }}
														color='primary'>
														Save
													</Button>
												</div>
											</div>
										</form>
									</div>
								</section>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}
