import React from "react";
import { Button, Row, Col } from "reactstrap";
import ReactPaginate from "react-paginate";
import ClassroomCard from "../../components/cards/ClassroomCard";
import API from "./../../helpers/api.jsx";

import originalMoment from "moment";
import { extendMoment } from "moment-range";
import "rc-time-picker/assets/index.css";
import { notify } from "../../helpers/common.jsx";

import { add } from "draft-js/lib/DraftEntity";
const moment = extendMoment(originalMoment);
const SERVERDIR = process.env.REACT_APP_API;
const BASEDIR = process.env.REACT_APP_BASEDIR;
class ClassroomSelect extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      limit: 4,
      count: 0,
      active: 1,
      rooms: [],
      currentRooms: [],
    };
  }

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    this.showClassroomPage(selectedPage + 1);
    window.scrollTo(0, 0);
  };

  goBack = (event) => {
    event.preventDefault();
    this.props.setData({
      ...this.props.data.data,
      currentRooms: [],
      activeTab: "1",
      backClicked: true,
    });
    this.props.nextStep("1");
  };

  handleClassroomCheck = (id) => {
    const { currentRooms, rooms } = this.props.data.data;

    if (rooms[rooms.findIndex((x) => x.id === id)].checked === "") {
      rooms[rooms.findIndex((x) => x.id === id)].checked = id;
    } else {
      rooms[rooms.findIndex((x) => x.id === id)].checked = "";
    }

    let result = rooms.filter(function (e) {
      return e.checked.length > 0;
    });

    this.setState({
      rooms: result,
    });
  };
  addSession = (e) => {
    e.preventDefault();
    const {
      rooms,
      isOpen,
      editorState,
      activeTab,
      active,
      allSuggestions,
      backClicked,
      isFileUploading,
      loading,
      modalDate,
      modalWarning,
      value,
      currentRooms,
      dateRangeValue,
      startTime,
      endTime,
      domainsList,
      session,
      count,
      limit,
      prevEndDate,
      prevStartDate,
      selectedDomains,
      suggestions,
      skills,
      course,
      numDays,
      hourlyChunks,
      minTime,
      maxTime,
      startDate,
      endDate,
      schedule,
      ...rest
    } = this.props.data.data;
    this.props.setData({
      ...rest,
      rooms: this.state.rooms,
    });

    const request = [
      {
        session: {
          ...rest,
          end: moment(endDate).format(),
          start: moment(startDate).format(),
          startTime: `${moment(startDate).format("YYYY-MM-DD")} ${startTime}`,
          endTime: `${moment(endDate).format("YYYY-MM-DD")} ${endTime}`,
          SessionImage: session.SessionImage || course.image,
          courseId: session.courseId || course.id,
          difficulty: session.difficulty || course.difficulty,
          scheduleData: {
            numDays,
            hourlyChunks,
            minTime,
            maxTime,
            startDate,
            schedule,
          },
        },

        room: this.state.rooms.map((item) => {
          return {
            id: item.id,
          };
        }),
        actionBy: "admin",
        domains: session.domain || course.domains,
        profileId: this.state.profileId,
      },
    ];
    if (window.location.href.includes("edit")) {
      API.post(`${SERVERDIR}Sessions/duplicateSession`, request)
        .then((res) => {
          this.setState({
            loading: false,
          });
          console.log(res.status, res);
          if (res.status === 200) {
            console.log("SUBMITTED SUCCESSFULLY");
            notify({ type: "success", message: "SUBMITTED SUCCESSFULLY" });
            this.props.history.push(BASEDIR + "/admin/sessionManagement/1");
          } else {
            throw res.status;
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          const {
            response: {
              data: {
                error: { message },
              },
            } = {},
          } = error;
          notify(
            {
              type: "danger",
              message:
                message === "Authorization Required"
                  ? "You are not able to add a session"
                  : message,
            },
            this
          );
        });
    } else {
      API.post(`${SERVERDIR}Sessions/newSession`, request)
        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.status === 200) {
            console.log("SUBMITTED SUCCESSFULLY");
            notify({ type: "success", message: "success" }, this);
            this.props.history.push(BASEDIR + "/admin/sessionManagement/1");
          } else {
            throw res.status;
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log("error:", error);
          const {
            response: {
              data: {
                error: { message },
              },
            } = {},
          } = error;
          notify(
            {
              type: "danger",
              message:
                message === "Authorization Required"
                  ? "You are not able to add a session"
                  : message,
            },
            this
          );
        });
    }
  };
  render() {
    return (
      <>
        <h1>Classroom Selection</h1>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title mb-0">New Session</h1>
              </div>
            </div>
            <div className="subtitle mt-0">
              <h5 className="mt-0">
                Please select one or multiple classroom preference. When you are
                done click on the « Publish » button.
              </h5>
            </div>

            <div className="col-xl-12">
              <div className="content-body">
                <div className="row">
                  <div className="col-12 cards-list-container">
                    {this.props.data?.data?.rooms?.map((room, key) => {
                      return (
                        <ClassroomCard
                          key={room.id}
                          room={room}
                          index={key}
                          handleClassroomCheck={this.handleClassroomCheck}
                          selection={true}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {this.state.count > 1 ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.count}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                initialPage={this.state.active - 1}
                forcePage={this.state.active - 1}
              />
            ) : null}
            <div className="row" style={{ justifyContent: "center" }}>
              <button
                type="button"
                onClick={this.goBack}
                className="btn btn-primary"
                style={{
                  borderRadius: "7px",
                  backgroundColor: "#444444",
                  BoxShadow: "0px 0px 12px",
                  width: "40%",
                }}
              >
                Back
              </button>
              <button
                disabled={this.props.data.isFileUploading}
                onClick={this.addSession}
                className="btn btn-primary"
                style={{ borderRadius: "7px", width: "40%" }}
              >
                Publish
              </button>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
export default ClassroomSelect;
