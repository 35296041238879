import React , {useState , useEffect , useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import ReactPaginate from 'react-paginate';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row, Table } from 'reactstrap';
import ModalProfilePreview from '../../components/modals/ModalProfilePreview.jsx';
import API from "./../../helpers/api.jsx";
import Loader from "react-loader-spinner";
import moment from "moment";


const BASEDIR = process.env.REACT_APP_BASEDIR;
const paymentDropdown = [
  { label: "Payment", value: "" },
  { label: "Online", value: "Online" },
  { label: "Onsite", value: "Onsite" },
  { label: "Post Wire", value: "PostWire" },
  { label: "Scholarship", value: "scholarship" },
];

export default function MembersTracking({ match }) {
    const notificationAlert = useRef();
    const [loading, setloading] = useState(false)
    const [count, setcount] = useState(0);
    const [limit, setlimit] = useState(20);
    const [totalCount, settotalCount] = useState(0);
    const [active, setactive] = useState(Number(match?.params?.page) || 1);
    const [filters, setfilters] = useState({ payment: "", keyword: "" });
    const [keyword, setkeyword] = useState('')
    const [profiles, setprofiles] = useState([]);
    const [profileToPreview, setprofileToPreview] = useState({});
    const [modalPreviewProfileVisible, setmodalPreviewProfileVisible] = useState(false);


    const getProfiles = async (key) => {
      const accessToken = localStorage.getItem("WeCodeLand_jwt");
      const skip = (key - 1) * limit;
      try {
        setloading(true);
        const profiles = await API.get(
          `TrackingForms/list?limit=${limit}&skip=${skip}&access_token=${accessToken}`, {params : {
            content : filters
          }}
        );

        if (profiles.status === 200) {
          console.log("profiles content :", profiles.data);
          const pageCount = Math.ceil(profiles?.data?.count / limit);
          console.log("pageCount", pageCount);
          console.log("profiles?.data?.count", profiles?.data?.count);
          setcount(pageCount);
          setloading(false);
          setprofiles(profiles.data.data);
          settotalCount(profiles.data.count);
        } else {
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        console.log("error: ", error);
      }
    };

    useEffect(() => {
        setactive(1);
        getProfiles(1);
      //call api
    }, [filters])

    useEffect(() => {
        getProfiles(active);
    }, [active])
    
    const filterChange=(e)=>{
      e.preventDefault();
      setfilters({ ...filters, [e.target.name]: e.target.value });
    }
    const handleInputChange=(e)=>{
      setkeyword(e.target.value);
    }

    const handlePageClick = (data) => {
      console.log("data",data);
       const selectedPage = data.selected;
       setactive(selectedPage + 1);

       window.history.pushState(
         null,
         null,
         BASEDIR + `/admin/membersTracking/${selectedPage + 1}`
       );
    };

    const exportData = async () => {
      const accessToken = localStorage.getItem("WeCodeLand_jwt");
      try {
        setloading(true);
        const csvData = await API.post(
          `TrackingForms/export?access_token=${accessToken}`,
          {
            ...filters,
          }
        );

        if (csvData.status === 200) {
         const link = document.createElement("a");
         link.href = csvData.data;
         link.setAttribute("download", `membersTrackingCSV.csv`);

         // Append to html link element page
         document.body.appendChild(link);

         // Start download
         link.click();

         // Clean up and remove the link
         link.parentNode.removeChild(link);
         setloading(false);
        } else {
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        console.log("error: ", error);
      }
    };

    
    

    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Members Tracking</h1>
                </div>
                <div className="float-right d-flex" style={{ marginTop: "2%" }}>
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setfilters({ ...filters, keyword });
                    }}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={(e) => {
                            e.preventDefault();
                            setfilters({ ...filters, keyword });
                          }}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        style={{ position: "relative" }}
                        placeholder={"Search..."}
                        value={keyword}
                        name="keyword"
                        onChange={handleInputChange}
                      />
                      <span
                        onClick={() => {
                          setkeyword("");
                          setfilters({ payment: "", keyword: "" });
                        }}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "8px",
                          top: "12px",
                          zIndex: "100",
                          display: keyword !== "" ? "block" : "none",
                        }}
                        className="fa fa-trash"
                      ></span>
                    </InputGroup>
                  </form>
                  <span
                    className="export-button"
                    onClick={() => exportData()}
                  >
                    <i className="fa fa-external-link"></i>&nbsp;Export
                  </span>
                </div>
              </div>
              {/* /////// */}
              {loading ? (
                <Loader
                  type="ThreeDots"
                  color="#D43272"
                  height={40}
                  width={40}
                  timeout={900000}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    minHeight: "80vh",
                  }}
                />
              ) : (
                <>
                  <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                    <section className="box ">
                      <div className="content-body">
                        <div className="row">
                          <div className="col-12">
                            {!loading &&
                              (filters?.keyword !== "" ||
                                filters?.payment !== "") && (
                                <span className="results-count">
                                  {totalCount} results were found
                                </span>
                              )}
                            <Table hover responsive className="members-table">
                              <thead>
                                <tr>
                                  <th>
                                    <select
                                      className="filter-col"
                                      id="inputPayment"
                                      name="payment"
                                      onChange={filterChange}
                                      value={filters.payment}
                                    >
                                      {paymentDropdown.map((data, key) => {
                                        return (
                                          <option value={data.value} key={key}>
                                            {data.label}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </th>
                                  <th>Step</th>
                                  <th>Name</th>
                                  <th>Info</th>
                                  <th>Email</th>
                                  <th>createdAt</th>
                                  <th>Phone</th>
                                  <th>City</th>
                                  <th>Session</th>
                                </tr>
                              </thead>
                              <tbody>
                                {profiles?.map((profile, key) => {
                                  return (
                                    <tr key={key}>
                                      <td className="text-cell email-cell">
                                        {profile?.paymentMethod}
                                      </td>
                                      <td className="text-cell email-cell">
                                        {profile?.step}
                                      </td>
                                      <td className="text-cell email-cell">
                                        {profile?.student?.fullName}
                                      </td>
                                      <td
                                        onClick={() => {
                                          setmodalPreviewProfileVisible(true);
                                          setprofileToPreview(profile?.student);
                                        }}
                                      >
                                        <i className="fa fa-info clickable purple"></i>
                                      </td>
                                      <td className="text-cell email-cell">
                                        {profile?.student?.email}
                                      </td>
                                      <td className="text-cell email-cell">
                                        {moment(profile?.createdAt).format(
                                          "YYYY-MM-DD, h:mm:ss a"
                                        ) || "-"}
                                      </td>
                                      <td className="text-cell email-cell">
                                        {profile?.phone}
                                      </td>
                                      <td className="text-cell email-cell">
                                        {profile?.address}
                                      </td>
                                      <td className="text-cell email-cell">
                                        <a
                                          href={`https://www.wecode.land/session/details/${profile.sessionId}`}
                                          target="_blank"
                                        >
                                          {profile?.session?.title}
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {profiles?.length === 0 && (
                                  <tr className="text-center">
                                    <td colSpan="10">
                                      <b>Oops! no data found</b>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* /////// */}
                  {count > 1 && (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={count}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      initialPage={active - 1}
                      forcePage={active - 1}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
        <ModalProfilePreview
          isOpen={modalPreviewProfileVisible}
          profile={profileToPreview}
          close={() => {
            setmodalPreviewProfileVisible(false);
            setprofileToPreview({});
          }}
        />
      </div>
    );
}
