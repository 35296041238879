import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import API from "./../../helpers/api.jsx";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import TimePicker from "react-time-picker";
import "rc-time-picker/assets/index.css";
import NewSessionForm from "../../components/NewSession/NewSessionFrom";

import ClassroomSelect from "../../components/NewSession/ClassroomSelect";
import classnames from "classnames";
import { useState } from "react";
class NewSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      data: {},
    };
  }
  setData = (data) => {
    this.setState({ ...this.state, data: data });
  };
  componentDidUpdate() {
    console.log("99999999999999", this.state);
  }
  changeActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    return (
      <div id="newSession">
        <h1>1</h1>
        <Nav className="justify-content-center" tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "1",
                "custom-nav-link": true,
              })}
            >
              1.Session Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "2",
                "custom-nav-link": true,
              })}
              /*   onClick={() => this.changeActiveTab("2")} */
            >
              2.Available classrooms
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <NewSessionForm
              id={this.props.match.params.id}
              nextStep={this.changeActiveTab}
              setData={this.setData}
              data={this.state.data}
            />
          </TabPane>
          <TabPane tabId="2">
            {this.state?.data?.rooms?.length > 0 && this.state?.data?.rooms ? (
              <ClassroomSelect
                data={this.state}
                setData={this.setData}
                nextStep={this.changeActiveTab}
              />
            ) : null}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
export default NewSession;
