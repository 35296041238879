import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import SessionCard from "./../../../components/cards/SessionCard.jsx";
import TeacherCard from "./../../../components/cards/TeacherCard.jsx";
import { Link } from "react-router-dom";
import API from "./../../../helpers/api.jsx";
import { notify } from "./../../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import ClassroomCard from "../../../components/cards/ClassroomCard.jsx";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ModalPayment from "./../../../components/modals/ModalPayment";
import ModalConfirmation from "./../../../components/modals/ModalConfirmation";
import Search from "./../../../components/search/Search";
import ReactPaginate from "react-paginate";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topOfweekObj: [],
      upcomingSessions: [],
      ongoingSessions: [],
      topOfWeek: [],
      limit: 8,
      count: 0,
      active: 1,
      searchedSessions: [],
      loading: true,
      profileId: localStorage.getItem("WeCodeLand_user"),
      modalPayment: false,
      sessionToSubscribeTo: {},
      isSearchResult: false,
      filter: {},
      modalUnsubscribe: false,
      sesionToUnsubscribeTo: {},
      studentUnsubscribingId: "",
      connectedIsSubscribed: false,
    };
  }

  async componentDidMount() {
    const access_token = localStorage.getItem("WeCodeLand_jwt");
    axios.defaults.headers.common["Authorization"] = access_token;
    await this.getTopOfWeek(this.state.active);
    await this.getUpcomingSessions(this.state.active);
    await this.getOngoingSessions(this.state.active);
    /*  this.setState({
    topOfWeek: this.populateTopOfWeek(this.state.topOfweekObj)
   }) */
  }

  /* populateTopOfWeek =(topOfWeek)=>{
   if (topOfWeek.length> 0){
    const classData= topOfWeek[0].classData;
    const roomData= topOfWeek[1].roomData;
    const tutorData= topOfWeek[2].tutorData;
    let finalArray =[];
    for (var i = 0; i < 2; i++) {
      if (classData.length > i){
        finalArray.push(classData[i])
      }
      if (roomData.length > i){
        finalArray.push(roomData[i])
      }
      if (tutorData.length > i){
        finalArray.push(tutorData[i])
      }
  }
  console.log("finalArray", finalArray);

  return finalArray;
    }
    else return [];
  } */

  getTopOfWeek = async (key) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = 100;
    const studentId = localStorage.getItem("WeCodeLand_user");
    try {
      const response = await API.get(`Sessions/topRated`, {
        params: {
          limit: limit,
          studentId: studentId,
          access_token: accessToken,
        },
      });

      if (response.status === 200) {
        this.setState({
          topOfweekObj: response.data,
          active: key,
        });
        /* const pageCount = Math.ceil(rooms.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };
  getUpcomingSessions = async (key) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const studentId = localStorage.getItem("WeCodeLand_user");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      const upcomingSessions = await API.get(`Profiles/upcomingSessions`, {
        params: {
          studentId: studentId,
          limit: limit,
          skip: skip,
          access_token: accessToken,
        },
      });

      if (upcomingSessions.status === 200) {
        this.setState({
          upcomingSessions: upcomingSessions.data.result,
          active: key,
        });
        /* const pageCount = Math.ceil(upcomingSessions.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };
  getOngoingSessions = async (key) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const studentId = localStorage.getItem("WeCodeLand_user");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      const ongoingSessions = await API.get(`Profiles/ongoingSessions`, {
        params: {
          studentId: studentId,
          limit: limit,
          skip: skip,
          access_token: accessToken,
        },
      });

      if (ongoingSessions.status === 200) {
        this.setState({
          ongoingSessions: ongoingSessions.data.result,
          active: key,
        });
        /* const pageCount = Math.ceil(ongoingSessions.data.count / this.state.limit);
        this.setState({ count: pageCount }); */
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  buttonCLickHandler = async (room, index) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      let result = await API.post(`Rooms/displayMask`, null, {
        params: {
          access_token: accessToken,
          value:
            room && room.status && room.status.toUpperCase() === "NEW"
              ? true
              : false,
          roomId: room && room.id,
        },
      });
      if (result.status === 200) {
        console.log("changed SUCCESSFULLY");
        notify(
          {
            type: "success",
            message:
              room && room.status && room.status.toUpperCase() === "NEW"
                ? "Room published"
                : "Room placed in drafts",
          },
          this
        );
        /* this.getRooms(this.state.active); */
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      this.setState({
        loading: false,
      });
      notify({ type: "danger", message: "something went wrong" }, this);
    } finally {
    }
  };

  unsubscribe = async (session, connectedIsSubscribed, studentId) => {
    try {
      console.log("session: ", session);
      let result = await API.post(`Subscriptions/addRemove`, null, {
        params: {
          value: !connectedIsSubscribed,
          classId: session && session.id,
          studentId: studentId,
        },
      });
      if (result.status === 200) {
        console.log("subscribed SUCCESSFULLY");
        notify(
          {
            type: "success",
            message: !connectedIsSubscribed
              ? "Subscribed SUCCESSFULLY"
              : "UnSubscribed SUCCESSFULLY",
          },
          this
        );
        await this.getOngoingSessions(this.state.active);
        await this.getUpcomingSessions(this.state.active);
        await this.getTopOfWeek(this.state.active);
        this.toggleModalUnsubscribe();
        this.setState({
          loading: false,
          idLoading: "",
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      this.setState({
        loading: false,
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  };

  participateButtonCLickHandler = async (session, index) => {
    const studentId = localStorage.getItem("WeCodeLand_user");
    this.setState({
      loading: true,
      idLoading: session.id,
    });
    const connectedIsSubscribed = !!(
      session &&
      session.subscriptionsSession &&
      session.subscriptionsSession
        .filter((subscription) => subscription.studentId === studentId)
        .map((subscription) => subscription.going)[0]
    );
    if (connectedIsSubscribed) {
      this.openModalUnsubscribe(session, connectedIsSubscribed, studentId);
    } else {
      this.setState(
        {
          sessionToSubscribeTo: session,
        },
        () => this.toggleModalPayment()
      );
    }
  };

  toggleModalPayment = () => {
    this.setState({
      modalPayment: !this.state.modalPayment,
    });
  };

  toggleModalUnsubscribe = () => {
    this.setState({
      modalUnsubscribe: !this.state.modalUnsubscribe,
    });
  };
  openModalUnsubscribe = (session, connectedIsSubscribed, studentId) => {
    this.setState({
      modalUnsubscribe: true,
      sesionToUnsubscribeTo: session,
      studentUnsubscribingId: studentId,
      connectedIsSubscribed: connectedIsSubscribed,
    });
  };

  onFinish = async () => {
    await this.getOngoingSessions(this.state.active);
    await this.getUpcomingSessions(this.state.active);
    await this.getTopOfWeek(this.state.active);
  };
  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  ///Search
  getSearchedResult = async (key, filter, date, isRangeSelected) => {
    console.log("received dates", date);
    console.log("date.start", date.start.format("YYYY-MM-DD"));
    const { limit } = this.state;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    this.setState({ filter: filter });
    if (
      this.checkProperties(filter) &&
      /* ((date && date.start.format("YYYY-MM-DD")) === (date && date.end.format("YYYY-MM-DD")) ) && (date && date.start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) */ !isRangeSelected
    ) {
      this.setState({
        isSearchResult: false,
      });
    } else {
      try {
        this.setState({
          loading: true,
          isSearchResult: true,
        });

        const params = {
          access_token: accessToken,
          content: filter,
          skip: (key - 1) * limit,
          limit: limit,
        };
        if (
          /* !((((date && date.start.format("YYYY-MM-DD")) === (date && date.end.format("YYYY-MM-DD")) ) && (date && date.start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")))) */ isRangeSelected
        ) {
          params.date = {
            start: moment(date.start).format("YYYY-MM-DDT") + "00:00:00.000Z",
            end: moment(date.end).format("YYYY-MM-DDT") + "00:00:00.000Z",
          };
        }

        const sessions = await API.get(`Sessions/findYourPath`, {
          params: params,
        });

        if (sessions.status === 200) {
          const pageCount = Math.ceil(sessions.data.count / this.state.limit);
          this.setState({ count: pageCount, totalCount: sessions.data.count });
          console.log("sessions searched content :", sessions.data.sessions);

          this.setState(
            {
              active: key,
              loading: false,
              searchedSessions: sessions.data.sessions,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.isSearchResult) {
      this.getSearchedResult(selectedPage + 1, this.state.filter);
    }
  };

  cancelSearch = () => {
    this.setState({
      isSearchResult: false,
    });
  };

  render() {
    const { rooms, upcomingSessions, ongoingSessions, topOfWeek } = this.state;
    return (
      <div id="home-page">
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                {!this.state.isSearchResult &&
                this.state.topOfweekObj &&
                this.state.topOfweekObj.length > 0 ? (
                  <div className="float-left">
                    <h1 className="title">Top of This week</h1>
                  </div>
                ) : null}
                <div className="float-right mt-2">
                  <Search
                    getSearchedResult={this.getSearchedResult}
                    cancelSearch={this.cancelSearch}
                  />
                </div>
              </div>

              {!this.state.isSearchResult &&
              this.state.topOfweekObj &&
              this.state.topOfweekObj.length > 0 ? (
                <div className="col-xl-12">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12 cards-list-container ">
                        <Carousel
                          arrowLeft={
                            <i className="fa fa-chevron-left purple clickable" />
                          }
                          arrowLeftDisabled={
                            <i className="fa fa-chevron-left disabled" />
                          }
                          arrowRight={
                            <i className="fa fa-chevron-right purple clickable" />
                          }
                          arrowRightDisabled={
                            <i className="fa fa-chevron-right disabled" />
                          }
                          addArrowClickHandler
                          dots
                          autoPlay={2000}
                          animationSpeed={1000}
                          stopAutoPlayOnHover={true}
                          slidesPerPage={4}
                          infinite
                          breakpoints={{
                            640: {
                              slidesPerPage: 1,
                              arrows: false,
                            },
                            900: {
                              slidesPerPage: 2,
                              arrows: false,
                            },
                          }}
                        >
                          {this.state.topOfweekObj &&
                            this.state.topOfweekObj.map((element, key) => {
                              /* if(element.hasOwnProperty("location")){
                        return (
                          <ClassroomCard
                            key={key}
                            room={element}
                            index={key}
                            buttonCLickHandler={this.buttonCLickHandler}
                            isPublic={true}
                          />
                        );}
                        else  */ if (element.hasOwnProperty("price")) {
                                return (
                                  <SessionCard
                                    key={key}
                                    session={element}
                                    index={key}
                                    buttonCLickHandler={
                                      this.participateButtonCLickHandler
                                    }
                                    isPublic={true}
                                    subscriptions={element.subscriptionsSession}
                                    isDisabled={
                                      this.state.loading &&
                                      this.state.idLoading === element.id
                                    }
                                  />
                                );
                              }
                              /* else{
                        return (
                          <TeacherCard
                            key={key}
                            teacher={element}
                            index={key}
                            buttonCLickHandler={this.buttonCLickHandler}
                            isPublic={true}
                          />
                        );} */
                            })}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.isSearchResult ? (
                <div className="col-xl-12">
                  <span className="bold">
                    found {this.state.totalCount} results
                  </span>
                  <div className="content-body mt-2">
                    <div className="row">
                      <div className="col-12 cards-list-container ">
                        {this.state.searchedSessions.map((session, key) => {
                          return (
                            <SessionCard
                              key={key}
                              session={session}
                              index={key}
                              buttonCLickHandler={
                                this.participateButtonCLickHandler
                              }
                              subscriptions={session.subscriptionsSession}
                              isDisabled={
                                this.state.loading &&
                                this.state.idLoading === session.id
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {this.state.count > 1 ? (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.count}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      initialPage={this.state.active - 1}
                      forcePage={this.state.active - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Col>
          </Row>
          {!this.state.isSearchResult ? (
            <div>
              <Row>
                <Col xs={12} md={12}>
                  {/* <div className="page-title">
                  <div className="float-left">
                    <h1 className="title">Top of This week</h1>
                  </div>
                </div> */}
                  <div className="page-title">
                    <div className="float-left mt-0">
                      <h1 className="title">Upcoming Sessions</h1>
                    </div>
                    <div className="float-right see-all-button">
                      <h3 className="font-weight-bold">
                        <Link to={`${BASEDIR}/upcomingSessions`}>See All</Link>
                      </h3>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 cards-list-container">
                          {upcomingSessions.slice(0, 4).map((session, key) => {
                            return (
                              <SessionCard
                                key={key}
                                session={session}
                                index={key}
                                buttonCLickHandler={
                                  this.participateButtonCLickHandler
                                }
                                subscriptions={session.subscriptionsSession}
                                isDisabled={
                                  this.state.loading &&
                                  this.state.idLoading === session.id
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className="page-title">
                    <div className="float-left mt-0">
                      <h1 className="title">Ongoing Sessions</h1>
                    </div>
                    <div className="float-right see-all-button">
                      <h3 className="font-weight-bold">
                        <Link to={`${BASEDIR}/ongoingSessions`}>See All</Link>
                      </h3>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="content-body">
                      <div className="row">
                        <div className="col-12 cards-list-container">
                          {ongoingSessions.slice(0, 4).map((session, key) => {
                            return (
                              <SessionCard
                                key={key}
                                session={session}
                                index={key}
                                buttonCLickHandler={
                                  this.participateButtonCLickHandler
                                }
                                subscriptions={session.subscriptionsSession}
                                isDisabled={this.state.loading}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
        <ModalPayment
          key={this.state.sessionToSubscribeTo.id}
          isOpen={this.state.modalPayment}
          toggle={this.toggleModalPayment}
          onFinish={this.onFinish}
          sessionToSubscribeTo={this.state.sessionToSubscribeTo}
        />
        <ModalConfirmation
          isOpen={this.state.modalUnsubscribe}
          toggle={this.toggleModalUnsubscribe}
          disabledButton={false}
          confirm={() =>
            this.unsubscribe(
              this.state.sesionToUnsubscribeTo,
              this.state.connectedIsSubscribed,
              this.state.studentUnsubscribingId
            )
          }
          cancel={this.toggleModalUnsubscribe}
          title={"Cancel participation"}
          body={
            "Are you sure you want to cancel your participation? This process cannot be undone"
          }
          confirmText={"Confirm"}
        />
      </div>
    );
  }
}

export default Home;
