import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  CustomInput,
} from "reactstrap";
import DatePicker from "react-datepicker";
import API from "../../helpers/api.jsx";
import { notify } from "../../helpers/common.jsx";
import empty_pic_course from "./../../assets/img/course-default.png";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NumericInput from "react-numeric-input";
//import TimePicker from "react-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { Multiselect } from "multiselect-react-dropdown";
import ScheduleSelector from "react-schedule-selector";

import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { moment as moment1 } from "moment";

import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;
const levelList = ["Beginner", "Intermediate", "Advanced"];
const format = "HH:mm";
const now = moment().hour(0).minute(0);
const multiselectRef = React.createRef();

class NewSessionFrom extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sessionId: this.props.id && this.props.id,
      modalWarning: false,
      session: {},
      rooms: [],
      limit: 4,
      count: 0,
      active: 1,
      title: "",
      description: "",
      price: 0,
      discount: 0,
      minPlaces: 0,
      maxPlaces: 0,
      duration: 0.0,
      TimeWindowForCancel: 0,
      activeTab: "1",
      startTime: "10:00",
      endTime: "10:00",
      level: "Beginner",
      coursePhoto: {
        preview: empty_pic_course,
        data: {},
        container: {},
      },
      syllabus: EditorState.createEmpty(),
      skills: [],
      domainsList: [],
      domains: [],
      selectedDomains: [],
      value: "",
      suggestions: [],
      allSuggestions: {},
      modalDate: false,
      backClicked: false,
      dateRangeValue: moment.range(today.clone(), today.clone()),
      prevStartDate: moment.range(today.clone(), today.clone()).start,
      prevEndDate: moment.range(today.clone(), today.clone()).end,
      isOnline: false,
      sessionLink: "",
      instructorList: [],
      selectedInstructors: [],
      schedule: [],
      numDays: 7,
      hourlyChunks: 1,
      minTime: 8,
      maxTime: 17,
      startDate: moment(),
      endDate: moment().add("d", 6),
    };
    this.notificationAlert = React.createRef();
  }

  handleDateInput = (date) => {
    const endDate = date.clone();
    this.setState(
      {
        startDate: date,
        endDate: endDate.add("d", 6),
        numDays: 7,
      },
      console.log(this.state)
    );
  };

  handleEndDateInput = (date) => {
    this.setState({
      endDate: date,
      numDays: date.diff(this.state.startDate, "days") + 1,
    });
  };

  dateLableHandler = (date) => {
    return <span>{moment(date).format("ddd DD/MM")}</span>;
  };
  componentWillMount = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    const instructor = await API.get(`/Profiles/instructorsList`, {
      params: { access_token: accessToken, limit: 1000, skip: 0 },
    });
    this.setState({
      instructorList: instructor.data.data,
    });
    if (window.location.href.includes("create")) {
      await this.getCourseById(this.props.id);
    }
    if (window.location.href.includes("edit")) {
      console.log("edit", this.props.id);
      await this.getSessionDetails(this.props.id);
    }

    this.toggleModalWarning();
  };
  toggleModalWarning = () => {
    this.setState({ modalWarning: !this.state.modalWarning });
  };

  onlineSwitchHandler = (e) => {
    this.setState(
      {
        isOnline: e.target.checked,
      },
      () => this.getClassrooms(1)
    );
  };
  updateSelectedInstructor = (e) => {
    const names = multiselectRef.current.getSelectedItems();
    this.setState({ selectedInstructors: names });
  };
  firstSubmit = async (event) => {
    event.preventDefault();
    if (
      !this.state.backClicked ||
      (this.state.backClicked &&
        this.state.prevStartDate !==
          this.state.startDate.format("YYYY-MM-DD")) ||
      (this.state.backClicked &&
        this.state.prevEndDate !== this.state.endDate.format("YYYY-MM-DD"))
    ) {
      await this.getClassrooms(1);
    }
    this.setState({
      activeTab: "2",
    });
  };
  handleChange1 = (newSchedule) => {
    console.log("666", this.state);
  };
  myFormat = (num) => {
    return num + " days";
  };
  onSelectDateRange = (dateRangeValue, states) => {
    this.setState({
      dateRangeValue,
      states,
    });
  };

  toggleDateRangePicker = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  renderSelectionValue = () => {
    return (
      <div>
        <h5>
          {this.state.dateRangeValue.start.format("YYYY-MM-DD")}
          {" - "}
          {this.state.dateRangeValue.end.format("YYYY-MM-DD")}
        </h5>
      </div>
    );
  };

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        syllabus: editorState.getCurrentContent(),
      },
      () => {
        console.log(
          "this.state.editorState: ",
          convertToRaw(this.state.editorState.getCurrentContent())
        );
      }
    );
  };

  convertEditorContentToHtml = (editorContent) => {
    return draftToHtml(convertToRaw(editorContent));
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  showClassroomPage = (page) => {
    const { limit, rooms } = this.state;
    this.setState({
      currentRooms: rooms.slice((page - 1) * limit, (page - 1) * limit + limit),
    });
  };

  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  };
  updateMinPlacesInputValue = async (e) => {
    const target = e.target;
    this.setState({
      minPlaces: target.value,
    });
    if (this.state.maxPlaces < target.value) {
      this.setState({
        maxPlaces: target.value,
      });
    }
  };
  updateDurationInputValue = async (valueAsNumber) => {
    this.setState({
      duration: valueAsNumber,
    });
  };
  updateCancellationDaysInputValue = async (valueAsNumber) => {
    this.setState({
      TimeWindowForCancel: valueAsNumber,
    });
  };

  handleChangeStartTime = (time) => {
    this.setState({
      startTime: moment(time).format("HH:mm"),
    });
  };

  handleChangeEndTime = (time) => {
    this.setState({
      endTime: moment(time).format("HH:mm"),
    });
  };
  getCourseById = async (id) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const course = await API.get(
        `Courses/${id}` /* , {
          params: { userId: userId, access_token: accessToken }
        } */
      );

      if (course.status === 200) {
        this.setState(
          {
            course: course.data,
          },
          () => this.populateForm(course.data)
        );
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  getSessionDetails = async (sessionId) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const session = await API.get(`Sessions/details/${sessionId}`, {
        params: { access_token: accessToken },
      });

      if (session.status === 200) {
        this.setState(
          {
            session: {
              ...session.data,
              // syllabus: session.data.syllabus.getCurrentContent(),
            },
          },
          () => {
            this.populateForm(session.data);
          }
        );
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  populateForm = (prop) => {
    console.log("populateForm");
    prop = prop ? prop : {};
    if (prop.minPlace !== this.state.minPlaces) {
      this.setState({ minPlaces: prop.minPlace });
    }
    if (prop.maxPlace !== this.state.maxPlaces) {
      this.setState({ maxPlaces: prop.maxPlace });
    }
    if (prop.duration !== this.state.duration) {
      this.setState({ duration: prop.duration });
    }
    if (moment.utc(prop.startTime).format("HH:mm") !== this.state.startTime) {
      this.setState({ startTime: moment.utc(prop.startTime).format("HH:mm") });
    }
    if (moment.utc(prop.endTime).format("HH:mm") !== this.state.endTime) {
      this.setState({ endTime: moment.utc(prop.endTime).format("HH:mm") });
    }
    if (
      !(
        moment(prop.end).isSame(moment(), "day") &&
        moment(prop.start).isSame(moment(), "day")
      )
    ) {
      this.setState({
        dateRangeValue: moment.range(moment(prop.start), moment(prop.end)),
      });
    }
    if (prop.TimeWindowForCancel !== this.state.TimeWindowForCancel) {
      this.setState({ TimeWindowForCancel: prop.TimeWindowForCancel });
    }
    this.setState({ title: prop.title || "" });
    if (prop.isOnline) {
      this.setState({
        sessionLink:
          prop.onlineSessionLink && prop.onlineSessionLink.length > 0
            ? prop.onlineSessionLink
            : "",
      });
    }
    this.setState({ isOnline: prop.isOnline || false });
    this.setState({ description: prop.description || "" });
    this.setState({ price: prop.price || 0 });
    this.setState({ discount: prop.discount || 0 });
    this.setState({ level: prop.difficulty || "" });
    if (
      (prop && prop.domains && prop.domains.length > 0) ||
      (prop &&
        prop.sessionCourse &&
        prop.sessionCourse &&
        prop.sessionCourse.domains &&
        prop.sessionCourse.domains.length > 0)
    )
      this.setState({ domains: prop.domains || prop.sessionCourse.domains });

    this.setState({ skills: prop.prerequisite });
    if (
      prop &&
      prop.sessionCourse &&
      prop.sessionCourse.syllabus &&
      Object.keys(prop.sessionCourse.syllabus).length > 0
    ) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(
            prop && prop.sessionCourse && prop.sessionCourse.syllabus
          )
        ),
        syllabus: prop.syllabus,
      });
    }
    if (prop && prop.syllabus && Object.keys(prop.syllabus).length > 0) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(prop && prop.syllabus)
        ),
        syllabus: prop.syllabus,
      });
    }
    if (
      prop &&
      prop.sessionCourse &&
      prop.sessionCourse.image &&
      Object.keys(prop.sessionCourse.image).length > 0
    ) {
      this.setState({ coursePhoto: prop.image });
    }
  };
  getClassrooms = async (key) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      const allSuggestions = await API.get(`Rooms/list`, {
        params: {
          isOnline: this.state.isOnline,
          startTime:
            moment(this.state.dateRangeValue.start).format("YYYY-MM-DDT") +
            this.state.startTime,
          endTime:
            moment(this.state.dateRangeValue.end).format("YYYY-MM-DDT") +
            this.state.endTime,
          start: moment(this.state.dateRangeValue.start).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          end: moment(this.state.dateRangeValue.end).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          limit: 99999,
          skip: 0,
          access_token: accessToken,
        },
      });
      this.setState({
        prevStartDate: this.state.dateRangeValue.start.format("YYYY-MM-DD"),
        prevEndDate: this.state.dateRangeValue.end.format("YYYY-MM-DD"),
      });
      if (allSuggestions.status === 200) {
        console.log(
          "allSuggestions?.data?.rooms?",
          allSuggestions?.data?.rooms,
          this.props.data
        );
        this.props.setData({
          ...this.props.data,
          rooms: allSuggestions?.data?.rooms?.map((v) => ({
            ...v,
            checked: "",
          })),
          active: key,
        });
        const pageCount = Math.ceil(
          allSuggestions.data.count / this.state.limit
        );
        this.setState({ count: pageCount }, () => this.showClassroomPage(1));
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  submitFirstForm = (e) => {
    e.preventDefault();
    const { instructorList, selectedInstructors, ...rest } = this.state;
    let result = selectedInstructors.map((a) => a.id);
    this.props.setData({ ...rest, contributorsId: result });
    this.getClassrooms("1");
    this.props.nextStep("2");
  };
  render() {
    const { currentRooms, session, skills, value, rooms } = this.state;
    const roomInputProps = {
      placeholder: "No Room selected",
      value,
      onChange: this.onChange,
    };
    return (
      <div id="newSession">
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title" style={{ textTransform: "none" }}>
                    New Session
                  </h1>
                </div>
              </div>

              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header">
                      <h2 className="title float-left secondary-title">
                        Session informations
                      </h2>
                    </header>
                    <div className="content-body">
                      <form onSubmit={this.submitFirstForm}>
                        <div>
                          <CustomInput
                            type="switch"
                            checked={this.state.isOnline}
                            name="isOnline"
                            id="isOnline"
                            label={this.state.isOnline ? "Online" : "On-site"}
                            bsSize="lg"
                            onChange={this.onlineSwitchHandler}
                          />{" "}
                          {this.state.isOnline && (
                            <div className="mb-4">
                              <label
                                htmlFor="inputMinPlaces"
                                style={{ fontWeight: "bold" }}
                              >
                                Session link
                              </label>
                              <input
                                type="text"
                                min="1"
                                name="sessionLink"
                                onChange={this.updateInputValue}
                                value={this.state.sessionLink}
                                className="form-control"
                                id="sessionLink"
                                placeholder="Session link"
                                style={{ borderRadius: "4px" }}
                                required={this.state.isOnline}
                              />
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="form-group col-md-3">
                            <label
                              htmlFor="inputMinPlaces"
                              style={{ fontWeight: "bold" }}
                            >
                              Minimum places
                            </label>
                            <input
                              type="number"
                              min="1"
                              name="minPlaces"
                              onChange={this.updateMinPlacesInputValue}
                              value={this.state.minPlaces}
                              className="form-control"
                              id="inputMinPlaces"
                              placeholder="Minimum places"
                              style={{ borderRadius: "4px" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label
                              htmlFor="inputMaxplaces"
                              style={{ fontWeight: "bold" }}
                            >
                              Maximum places
                            </label>
                            <input
                              type="number"
                              min={
                                this.state.minPlaces > 0
                                  ? this.state.minPlaces
                                  : "1"
                              }
                              name="maxPlaces"
                              onChange={this.updateInputValue}
                              value={this.state.maxPlaces}
                              className="form-control"
                              id="inputMaxplaces"
                              placeholder="Maximum places"
                              style={{ borderRadius: "4px" }}
                              required
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label
                              htmlFor="inputDuration"
                              style={{ fontWeight: "bold" }}
                            >
                              Duration
                            </label>
                            <NumericInput
                              min={0.0}
                              onChange={this.updateDurationInputValue}
                              value={this.state.duration}
                              className="form-control"
                              id="inputDuration"
                              placeholder="Duration in hours"
                              style={{ borderRadius: "4px" }}
                              required
                              step={0.1}
                              precision={2}
                            />
                          </div>
                          {/* <div className="form-group col-md-3">
                            <label
                              htmlFor="inputStartDate"
                              style={{ fontWeight: "bold" }}
                            >
                              Start date - Finish date
                            </label>
                            <div>{this.renderSelectionValue()}</div>
                            <div>
                              <input
                                className="btn btn-primary"
                                type="button"
                                value="Open Calendar"
                                onClick={this.toggleDateRangePicker}
                                style={{ borderRadius: "7px" }}
                              />
                            </div>
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="form-group col-md-3">
                            <label
                              htmlFor="imputTitle"
                              style={{ fontWeight: "bold" }}
                            >
                              Start hour
                            </label>

                            <TimePicker
                              showSecond={false}
                              defaultValue={now}
                              popupStyle={{
                                width: "200px",
                                maxWidth: "200px",
                              }}
                              style={{
                                borderRadius: "4px",
                                width: "200px",
                                display: "block",
                              }}
                              onChange={this.handleChangeStartTime}
                              value={moment(this.state.startTime, "HH:mm")}
                              format={format}
                              use12Hours={false}
                              inputReadOnly
                              allowEmpty={false}
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label
                              htmlFor="imputTitle"
                              style={{ fontWeight: "bold" }}
                            >
                              Finish hour
                            </label>

                            <TimePicker
                              showSecond={false}
                              defaultValue={now}
                              popupStyle={{
                                width: "200px",
                                maxWidth: "200px",
                              }}
                              style={{
                                borderRadius: "4px",
                                width: "200px",
                                display: "block",
                              }}
                              onChange={this.handleChangeEndTime}
                              value={moment(this.state.endTime, "HH:mm")}
                              format={format}
                              use12Hours={false}
                              inputReadOnly
                              allowEmpty={false}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              htmlFor="inputCancellation"
                              style={{ fontWeight: "bold" }}
                            >
                              Days allowed before cancellation
                            </label>
                            <NumericInput
                              min={0}
                              onChange={this.updateCancellationDaysInputValue}
                              value={this.state.TimeWindowForCancel}
                              className="form-control"
                              id="inputCancellation"
                              placeholder="days before cancellation"
                              style={{ borderRadius: "4px" }}
                              required
                              format={this.myFormat}
                            />
                          </div>
                        </div>
                        <h3 className="secondary-title">Course chosen</h3>
                        <br />
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <img
                              alt=""
                              src={
                                session &&
                                session.sessionCourse &&
                                session.sessionCourse.image &&
                                Object.keys(session.sessionCourse.image)
                                  .length > 0
                                  ? `${SERVERDIR}containers/${session.sessionCourse.image.container}/download/${session.sessionCourse.image.name}`
                                  : this.state.coursePhoto.preview
                              }
                            />
                            {/*      <div className="profileimg-input">
                                <Input
                                  type="file"
                                  name="coursePhoto"
                                  id="exampleFile"
                                  onChange={this.photoChangeHandler}
                                  style={{ marginTop: "10px" }}
                                />
                              </div> */}
                          </div>

                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <label
                                htmlFor="imputTitle"
                                style={{ fontWeight: "bold" }}
                              >
                                Title
                              </label>
                              <input
                                disabled
                                type="text"
                                name="title"
                                maxLength="40"
                                onChange={this.updateInputValue}
                                value={this.state.title}
                                className="form-control"
                                id="imputTitle"
                                placeholder="Title"
                                style={{ borderRadius: "4px" }}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="inputObjective"
                                style={{ fontWeight: "bold" }}
                              >
                                Description
                              </label>
                              <Input
                                disabled
                                type="textarea"
                                rows="5"
                                maxLength="350"
                                cols="50"
                                className="form-control custom-fixed-textarea"
                                name="description"
                                value={this.state.description}
                                onChange={this.updateInputValue}
                                id="inputDescription"
                                placeholder="Tell us about the course"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="form-group">
                            <label
                              htmlFor="inputSyllabus"
                              style={{ fontWeight: "bold" }}
                            >
                              Syllabus
                            </label>
                            <Editor
                              editorState={this.state.editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={this.onEditorStateChange}
                              readOnly
                            />

                            {/* <div dangerouslySetInnerHTML={{ __html: this.convertEditorContentToHtml(this.state.editorState.getCurrentContent())}}/> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="inputPrice"
                                style={{ fontWeight: "bold" }}
                              >
                                Price per Person (TND)
                              </label>
                              <input
                                disabled
                                type="number"
                                min="0"
                                name="price"
                                onChange={this.updateInputValue}
                                value={this.state.price}
                                className="form-control"
                                id="inputPrice"
                                placeholder="Insert price here"
                                style={{ borderRadius: "4px" }}
                                required
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="inputOffer"
                                style={{ fontWeight: "bold" }}
                              >
                                Offer percentage
                              </label>
                              <input
                                type="number"
                                min="0"
                                max="100"
                                name="discount"
                                onChange={this.updateInputValue}
                                value={this.state.discount}
                                className="form-control"
                                id="inputOffer"
                                placeholder="Insert a percentage here"
                                style={{ borderRadius: "4px" }}
                                required
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="imputType"
                                style={{ fontWeight: "bold" }}
                              >
                                Course Level
                              </label>
                              <select
                                disabled
                                id="imputType"
                                name="level"
                                className="form-control"
                                style={{ borderRadius: "4px" }}
                                onChange={this.updateInputValue}
                                value={this.state.level}
                                required
                              >
                                {levelList?.map((data, key) => {
                                  return (
                                    <option value={data} key={key}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputSkills"
                                style={{ fontWeight: "bold" }}
                              >
                                Required Skills
                              </label>
                              {skills?.map((tag, index) => (
                                <li key={index} className="tag">
                                  <span className="tag-title">{tag}</span>
                                </li>
                              ))}
                            </div>
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputDomains"
                                style={{ fontWeight: "bold" }}
                              >
                                Domain(s)
                              </label>
                              <ul>
                                {this.state.domains.map((domain) => (
                                  <p key={domain}>{domain.name || domain}</p>
                                ))}
                              </ul>
                            </div>
                            <div className="form-group col-md-18">
                              <label
                                htmlFor="inputIntrest"
                                style={{ fontWeight: "bold" }}
                              >
                                Instructors{" "}
                              </label>
                              <Multiselect
                                id="inputInterest"
                                ref={multiselectRef}
                                name="instructor"
                                selectedValues={this.state.selectedInstructors}
                                options={this.state.instructorList} // Options to display in the dropdown
                                onSelect={this.updateSelectedInstructor} // Function will trigger on select event
                                onRemove={this.updateSelectedInstructor} // Function will trigger on remove event
                                displayValue="fullName" // Property name to display in the dropdown options
                              />
                            </div>
                          </div>
                          <label
                            htmlFor="inputIntrest"
                            style={{ fontWeight: "bold" }}
                          >
                            Schedule{" "}
                          </label>{" "}
                          <div
                            style={{
                              display: "flex",
                              marginBottom: 10,
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div>
                              <label>Start Date</label>
                              <DatePicker
                                minDate={moment().format("L")}
                                selected={this.state.startDate}
                                onChange={this.handleDateInput}
                                id="startDate"
                                name="startDate"
                              />
                            </div>
                            <div>
                              <label>End Date</label>
                              <DatePicker
                                minDate={moment().format("L")}
                                selected={this.state.endDate}
                                onChange={this.handleEndDateInput}
                                id="endDate"
                                name="endDate"
                              />
                            </div>
                            <div>
                              <div>
                                {" "}
                                <label>Number of Days</label>
                              </div>
                              <div>
                                {" "}
                                <input
                                  type="number"
                                  min={1}
                                  max={360}
                                  value={this.state.numDays}
                                  onChange={(value) =>
                                    value.target.value > 0 &&
                                    value.target.value < 361
                                      ? this.setState({
                                          numDays: value.target.value,
                                        })
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <div>
                                <label>Minimum hour</label>
                              </div>
                              <input
                                type="number"
                                min={0}
                                max={this.state.maxTime - 1}
                                value={this.state.minTime}
                                onChange={(value) =>
                                  value.target.value > -1 &&
                                  value.target.value < this.state.maxTime
                                    ? this.setState({
                                        minTime: value.target.value,
                                      })
                                    : null
                                }
                              />
                            </div>
                            <div>
                              <div>
                                <label>Maximum hour</label>
                              </div>
                              <input
                                type="number"
                                min={this.state.minTime + 1}
                                max={24}
                                value={this.state.maxTime}
                                onChange={(value) =>
                                  value.target.value > this.state.minTime &&
                                  value.target.value < 25
                                    ? this.setState({
                                        maxTime: value.target.value,
                                      })
                                    : null
                                }
                              />
                            </div>
                            <div>
                              <div>
                                <label>hourly pieces</label>
                              </div>
                              <input
                                type="number"
                                min={1}
                                max={6}
                                value={this.state.hourlyChunks}
                                onChange={(value) =>
                                  value.target.value > 0 &&
                                  value.target.value < 7
                                    ? this.setState({
                                        hourlyChunks: value.target.value,
                                      })
                                    : null
                                }
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: 20 }}>
                            <ScheduleSelector
                              minTime={this.state.minTime}
                              maxTime={this.state.maxTime}
                              numDays={this.state.numDays}
                              startDate={this.state.startDate}
                              onChange={(newSchedule) => {
                                const sortedArray = newSchedule.sort(
                                  (a, b) =>
                                    new moment1(a).format("YYYYMMDD") -
                                    new moment1(b).format("YYYYMMDD")
                                );
                                this.setState({ schedule: sortedArray });
                              }}
                              hourlyChunks={this.state.hourlyChunks}
                              selection={this.state.schedule}
                              timeFormat="h:mma"
                              selectedColor="#CA2D5D"
                              renderDateLabel={this.dateLableHandler}
                            />
                            {/* <ScheduleSelector
                              selection={this.state.schedule}
                              numDays={5}
                              minTime={8}
                              maxTime={22}
                              hourlyChunks={2}
                              onChange={this.handleChange}
                            />*/}
                          </div>
                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                window.location.href =
                                  BASEDIR + "/admin/sessionManagement/1";
                              }}
                              className="btn btn-primary"
                              style={{
                                borderRadius: "7px",
                                backgroundColor: "#444444",
                                BoxShadow: "0px 0px 12px",
                                width: "40%",
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={{ borderRadius: "7px", width: "40%" }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/*     <Modal isOpen={this.state.isOpen} toggle={this.toggleDateRangePicker}>
          <ModalHeader>Select Start date - Finish date </ModalHeader>
          <ModalBody>
            <DateRangePicker
              value={this.state.dateRangeValue}
              onSelect={this.onSelectDateRange}
              singleDateRange={true}
              firstOfWeek={1}
              numberOfCalendars={2}
              selectionType="range"
              initialFromValue={false}
              onChange={this.onSelectDateRange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              onClick={this.toggleDateRangePicker}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal> */}
      </div>
    );
  }
}
export default NewSessionFrom;
