/** @format */

import React from "react";
import {
	Row,
	Col,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
} from "reactstrap";
import API from "./../../../helpers/api.jsx";
import { notify } from "./../../../helpers/common.jsx";
import empty_pic_course from "./../../../assets/img/course-default.png";
import NotificationAlert from "react-notification-alert";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TagsInput from "./../../../components/Tags/TagsInput.jsx";
import { Multiselect } from "multiselect-react-dropdown";
import ReactGA from "react-ga";

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;
const levelList = ["Beginner", "Intermediate", "Advanced"];

class NewCourseByTeacher extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "",
			description: "",
			price: 0,
			offer: 0,
			level: "Beginner",
			file: {
				originalFileName: "",
				name: "",
			},
			isFileUploading: false,
			potentialPartners: "",
			coursePhoto: {
				preview: empty_pic_course,
				data: {},
				container: {},
			},
			editorState: EditorState.createEmpty(),
			skills: [],
			domainsList: [],
			selectedDomains: [],
			domainsIndex: "0",
			modalConfirmAction: false,
			inputKey: Date.now(),
		};
		this.notificationAlert = React.createRef();
		this.multiselectRef = React.createRef();
	}

	componentWillMount = async () => {
		const accessToken = localStorage.getItem("WeCodeLand_jwt");
		API.defaults.headers.common["Authorization"] = accessToken;
		ReactGA.initialize("UA-173264282-1");
		await this.getDomains();
	};

	getDomains = async () => {
		const accessToken = localStorage.getItem("WeCodeLand_jwt");
		const domains = await API.get(`Domains?access_token=${accessToken}`);

		if (domains.status === 200) {
			console.log("domains :", domains.data);
			this.setState({ domainsList: domains.data });
		} else {
			console.log("error");
		}
	};

	updateSelectedDomains = () => {
		this.setState({
			selectedDomains: this.multiselectRef.current.getSelectedItems(),
		});
	};

	onEditorStateChange = editorState => {
		this.setState(
			{
				editorState,
			},
			() => {
				console.log(
					"this.state.editorState: ",
					convertToRaw(this.state.editorState.getCurrentContent())
				);
			}
		);
	};

	convertEditorContentToHtml = editorContent => {
		return draftToHtml(convertToRaw(editorContent));
	};

	selectedSkills = skills => {
		this.setState({ skills: skills }, () => {
			console.log("skills!!!", this.state.skills);
		});
	};
	selectedDomains = domains => {
		this.setState({ domains: domains }, () => {
			console.log("domains!!!", this.state.domains);
		});
	};

	updateInputValue = async e => {
		const target = e.target;
		const name = target.name;
		this.setState({
			[name]: target.value,
		});
	};

	addCourse = e => {
		// this.setState({
		//   loading: true
		// });
		console.log("event.target", e.target);

		e.preventDefault();
		console.log("this.state.file add course", this.state.file);

		const request = {
			title: this.state.title,
			description: this.state.description,
			image: this.state.coursePhoto.container,
			prerequisite: this.state.skills,
			price: this.state.price,
			discount: this.state.offer,
			difficulty: this.state.level,
			syllabus: convertToRaw(this.state.editorState.getCurrentContent()),
			domains: this.state.selectedDomains,
			file: this.state.file,
			profileId: localStorage.getItem("WeCodeLand_user"),
		};
		console.log("this.state.file in request", request.file);

		API.post(`${SERVERDIR}Courses/newCourse`, request)
			.then(res => {
				this.setState({
					loading: false,
				});
				if (res.status === 200) {
					ReactGA.event({
						category: "Course",
						action: "Created a Course",
					});
					console.log("SUBMITTED SUCCESSFULLY");
					notify({ type: "success", message: "success" }, this);
					this.props.history.push(BASEDIR + "/admin/courseManagement");
				} else {
					this.setState({ modalConfirmAction: false });
					throw res.status;
				}
			})
			.catch(error => {
				// this.setState({
				//   loading: false
				// });
				console.log("error:", error);
				const {
					response: {
						data: {
							error: { message },
						},
					} = {},
				} = error;
				notify(
					{
						type: "danger",
						message:
							message === "Authorization Required"
								? "You are not able to add a course"
								: message,
					},
					this
				);
				this.setState({ modalConfirmAction: false });
			});
	};

	//** UPLOAD COURSE IMAGE */
	photoChangeHandler = async e => {
		const file = e.target.files[0];
		const { coursePhoto } = this.state;
		let uploadedPhoto;
		if (e.target.name === "coursePhoto") {
			coursePhoto.preview = URL.createObjectURL(file);
			coursePhoto.data = file;
			uploadedPhoto = {
				file: coursePhoto,
				name: "coursePhoto",
			};
		}

		// const file = this.state.coursePhoto
		const fileData = new FormData();
		fileData.append("file", uploadedPhoto.file.data);

		try {
			let imageProfile = await API.post(
				`${SERVERDIR}Containers/img/upload`,
				fileData
			);
			if (imageProfile.status === 200) {
				console.log("uploadedPhoto: ", imageProfile.data);
				// notify({ type: 'success', message: "success" }, this);
				uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
				await this.setState({
					[uploadedPhoto.name]: uploadedPhoto.file,
				});
			} else {
				throw imageProfile.status;
			}
		} catch (error) {
			const {
				response: {
					data: {
						error: { message },
					},
				} = {},
			} = error;
			if (message.includes("contentType"))
				notify({ type: "danger", message: "Please upload an image" }, this);
			this.setState({
				inputKey: Date.now(),
			});
		} finally {
		}
	};

	resetCourse = () => {
		this.multiselectRef.current.resetSelectedValues();
		this.setState({
			title: "",
			description: "",
			coursePhoto: {
				preview: empty_pic_course,
				data: {},
				container: {},
			},
			file: {
				originalFileName: "",
				name: "",
			},
			editorState: EditorState.createEmpty(),
			price: 0,
			offer: 0,
			level: "Beginner",
			skills: [],
			selectedDomains: [],
		});
	};

	handelOnUploadFile = async course => {
		course.preventDefault();
		const { file } = this.state;
		const originalFileName = course.currentTarget.files[0];
		this.setState({
			isFileUploading: true,
		});

		const formData = new FormData();
		formData.append("file", course.target.files[0]);

		let fileUploaded = await API.post(
			`${SERVERDIR}Container2s/file/upload`,
			formData
		);
		if (fileUploaded.status === 200) {
			this.setState({ isFileUploading: false });
			file.originalFileName = originalFileName.name;
			file.name = fileUploaded.data.result.files.file[0].name;
			this.setState({
				file: file,
				isFileUploading: false,
			});
			notify({ type: "success", message: "file Uploaded" }, this);
		} else {
			throw fileUploaded.status;
		}
	};

	toggleModalConfirmAction = e => {
		e.preventDefault();
		this.setState({
			modalConfirmAction: !this.state.modalConfirmAction,
		});
	};

	render() {
		const { isFileUploading, skills } = this.state;
		return (
			<div>
				<div className='notification-popup'>
					<NotificationAlert ref='notificationAlert' />
				</div>
				<div className='content'>
					<Row>
						<Col xs={12} md={12}>
							<div className='page-title'>
								<div className='float-left'>
									<h1 className='title' style={{ textTransform: "none" }}>
										New Course
									</h1>
								</div>
							</div>

							<div className='row margin-0'>
								<div className='col-12'>
									<section className='box '>
										<header className='panel_header'></header>
										<div className='content-body'>
											<form id='myForm' onSubmit={this.addCourse} method='post'>
												<div className='row'>
													<div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
														<img alt='' src={this.state.coursePhoto.preview} />
														<div className='profileimg-input'>
															<Input
																type='file'
																key={this.state.inputKey}
																name='coursePhoto'
																id='exampleFile'
																onChange={this.photoChangeHandler}
																style={{ marginTop: "10px" }}
															/>
														</div>
													</div>

													<div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
														<div className='form-group'>
															<label
																htmlFor='imputTitle'
																style={{ fontWeight: "bold" }}>
																Title
															</label>
															<input
																type='text'
																name='title'
																maxLength='40'
																onChange={this.updateInputValue}
																value={this.state.title}
																className='form-control'
																id='imputTitle'
																placeholder='Title'
																style={{ borderRadius: "4px" }}
																required
															/>
														</div>
														<div className='form-group'>
															<label
																htmlFor='inputObjective'
																style={{ fontWeight: "bold" }}>
																Description
															</label>
															<Input
																type='textarea'
																rows='5'
																maxLength='350'
																cols='50'
																className='form-control custom-fixed-textarea'
																name='description'
																value={this.state.description}
																onChange={this.updateInputValue}
																id='inputDescription'
																placeholder='Tell us about the course'
																required
															/>
														</div>
													</div>
												</div>
												<div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
													<div className='form-group'>
														<label
															htmlFor='inputSyllabus'
															style={{ fontWeight: "bold" }}>
															Syllabus
														</label>
														<Editor
															editorState={this.state.editorState}
															toolbarClassName='toolbarClassName'
															wrapperClassName='wrapperClassName'
															editorClassName='editorClassName'
															onEditorStateChange={this.onEditorStateChange}
															toolbar={{
																options: [
																	"inline",
																	"blockType",
																	"fontSize",
																	"fontFamily",
																	"list",
																	"textAlign",
																	"colorPicker",
																	"link",
																	"remove",
																	"history",
																],
																fontFamily: {
																	options: [
																		"AmpleSoft",
																		"Mulish",
																		"Arial",
																		"Georgia",
																		"Impact",
																		"Tahoma",
																		"Times New Roman",
																		"Verdana",
																		"Open Sans",
																	],
																},
															}}
														/>

														{/* <div dangerouslySetInnerHTML={{ __html: this.convertEditorContentToHtml(this.state.editorState.getCurrentContent())}}/> */}
													</div>
												</div>
												<div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
													<div className='row'>
														<div className='form-group col-md-3'>
															<label
																htmlFor='imputTitle'
																style={{ fontWeight: "bold" }}>
																Price per Person (TND)
															</label>
															<input
																type='number'
																min='0'
																name='price'
																onChange={this.updateInputValue}
																value={this.state.price}
																className='form-control'
																id='imputTitle'
																placeholder='Insert price here'
																style={{ borderRadius: "4px" }}
																required
															/>
														</div>
														<div className='form-group col-md-3'>
															<label
																htmlFor='imputTitle'
																style={{ fontWeight: "bold" }}>
																Offer percentage
															</label>
															<input
																type='number'
																min='0'
																max='100'
																name='offer'
																onChange={this.updateInputValue}
																value={this.state.offer}
																className='form-control'
																id='imputTitle'
																placeholder='Insert a percentage here'
																style={{ borderRadius: "4px" }}
																required
															/>
														</div>
														<div className='form-group col-md-3'>
															<label
																htmlFor='imputType'
																style={{ fontWeight: "bold" }}>
																Course Level
															</label>
															<select
																id='imputType'
																name='level'
																className='form-control'
																style={{ borderRadius: "4px" }}
																onChange={this.updateInputValue}
																value={this.state.level}
																required>
																{levelList.map((data, key) => {
																	return (
																		<option value={data} key={key}>
																			{data}
																		</option>
																	);
																})}
															</select>
														</div>
														<div className='form-group col-md-3'>
															<label
																htmlFor='inputFile'
																style={{ fontWeight: "bold" }}>
																Attachment (optional)
															</label>
															<Input
																type='file'
																name='file'
																id='inputFile'
																onChange={this.handelOnUploadFile}
																style={{ marginTop: "10px" }}
															/>
														</div>
													</div>
													<div className='row'>
														<div className='form-group col-md-6'>
															<label
																htmlFor='imputSkills'
																style={{ fontWeight: "bold" }}>
																Required Skills
															</label>
															<TagsInput
																key={skills.length}
																placeholder='Enter a required skill and press the + button to add it'
																selectedTags={this.selectedSkills}
																tags={skills}
															/>
														</div>
														<div className='form-group col-md-6'>
															<label
																htmlFor='imputDomains'
																style={{ fontWeight: "bold" }}>
																Domain(s)
															</label>
															<Multiselect
																options={this.state.domainsList} // Options to display in the dropdown
																onSelect={this.updateSelectedDomains} // Function will trigger on select event
																onRemove={this.updateSelectedDomains} // Function will trigger on remove event
																displayValue='name' // Property name to display in the dropdown options
																ref={this.multiselectRef}
																placeholder='Select domain(s)'
																avoidHighlightFirstOption={true}
															/>
														</div>
													</div>
													<div
														className='row'
														style={{ justifyContent: "center" }}>
														<button
															type='button'
															onClick={this.resetCourse}
															className='btn btn-primary'
															style={{
																backgroundColor: "#444444",
																BoxShadow: "0px 0px 12px",
																width: "40%",
																borderRadius: "7px",
															}}>
															Cancel
														</button>
														<button
															disabled={isFileUploading}
															onClick={this.toggleModalConfirmAction}
															className='btn btn-primary'
															style={{ width: "40%", borderRadius: "7px" }}>
															Save
														</button>
													</div>
												</div>
											</form>
										</div>
									</section>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<Modal
					isOpen={this.state.modalConfirmAction}
					toggle={this.toggleModalConfirmAction}>
					<ModalHeader>Adding a new course</ModalHeader>
					<ModalBody>
						<h3>Are you sure you want to save these changes</h3>
					</ModalBody>
					<ModalFooter>
						<Button
							className='save-button'
							color='primary'
							form='myForm'
							key='submit'
							htmltype='submit'>
							{"Confirm"}
						</Button>{" "}
						<Button
							className='save-button'
							color='secondary'
							onClick={this.toggleModalConfirmAction}>
							{"Cancel"}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default NewCourseByTeacher;
