/** @format */

import React from "react";
import {
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import API from "./../../helpers/api.jsx";
import { notify } from "./../../helpers/common.jsx";
import empty_pic_course from "./../../assets/img/course-default.png";
import profilePhoto from "./../../assets/img/profile-general.jpg";
import NotificationAlert from "react-notification-alert";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TagsInput from "./../../components/Tags/TagsInput.jsx";
import { Multiselect } from "multiselect-react-dropdown";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;
const levelList = ["Beginner", "Intermediate", "Advanced"];

class EditCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      course: {},
      photoChanged: false,
      perspectivesPhotoChanged: false,
      objectifsPhotoChanged: false,
      id: this.props.match.params && this.props.match.params.id,
      title: "",
      description: "",
      price: 0,
      /* offer: 0, */
      courseCreator: "",
      level: "Beginner",
      file: {
        originalFileName: "",
        name: "",
      },
      isFileUploading: false,
      potentialPartners: "",
      coursePhoto: {
        preview: empty_pic_course,
        data: {},
        container: {},
      },
      objectifsPhoto: {
        preview: empty_pic_course,
        data: {},
        container: {},
      },
      perspectivesPhoto: {
        preview: empty_pic_course,
        data: {},
        container: {},
      },
      testimonials: [
        {
          photo: {
            preview: profilePhoto,
            data: {},
            container: {},
          },
          author: "",
          content: "",
        },
      ],
      editorState: EditorState.createEmpty(),
      objectifsEditorState: EditorState.createEmpty(),
      perspectivesEditorState: EditorState.createEmpty(),
      skills: [],
      categoriesList: [],
      categoryId: "",
      forWho: "",
      domainsList: [],
      selectedDomains: [],
      preSelectedDomains: [],
      domainsIndex: "0",
      modalConfirmAction: false,
      inputKey: Date.now(),
    };
    this.notificationAlert = React.createRef();
    this.multiselectRef = React.createRef();
  }

  componentWillMount = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    await this.getCourseById(this.state.id);
    await this.getDomains();
    await this.getCategories();
  };

  getDomains = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const domains = await API.get(`Domains?access_token=${accessToken}`);

    if (domains.status === 200) {
      this.setState({ domainsList: domains.data });
    } else {
      console.log("error");
    }
  };

  getCategories = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const categories = await API.get(`Categories?access_token=${accessToken}`);

    if (categories.status === 200) {
      this.setState({ categoriesList: categories.data });
    } else {
      console.log("error");
    }
  };

  updateSelectedDomains = () => {
    this.setState({
      selectedDomains: this.multiselectRef.current.getSelectedItems(),
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  onObjectifsEditorStateChange = (editorState) => {
    this.setState({
      objectifsEditorState: editorState,
    });
  };
  onPerspectivesEditorStateChange = (editorState) => {
    this.setState({
      perspectivesEditorState: editorState,
    });
  };

  convertEditorContentToHtml = (editorContent) => {
    return draftToHtml(convertToRaw(editorContent));
  };

  selectedSkills = (skills) => {
    this.setState({ skills: skills });
  };
  selectedDomains = (domains) => {
    this.setState({ domains: domains });
  };

  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  };
  populateForm = (prop) => {
    prop = prop ? prop : {};
    this.setState({ title: prop.title || "" });
    this.setState({ description: prop.description || "" });
    this.setState({ categoryId: prop.categoryId || "" });
    this.setState({ forWho: prop.forWho || "" });
    this.setState({ price: prop.price || 0 });
    this.setState({ courseCreator: prop.courseCreator || "" });
    /* this.setState({ offer: prop.discount || 0 }); */
    this.setState({ level: prop.difficulty || "Beginner" });
    if (prop.prerequisite && prop.prerequisite.length > 0) {
      this.setState({ skills: prop.prerequisite });
    }
    if (prop.syllabus && Object.keys(prop.syllabus).length > 0) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(prop.syllabus)
        ),
      });
    }
    if (
      prop.objectifs?.content &&
      Object.keys(prop.objectifs?.content).length > 0
    ) {
      this.setState({
        objectifsEditorState: EditorState.createWithContent(
          convertFromRaw(prop.objectifs?.content)
        ),
      });
    }
    if (
      prop.perspectives?.content &&
      Object.keys(prop.perspectives?.content).length > 0
    ) {
      this.setState({
        perspectivesEditorState: EditorState.createWithContent(
          convertFromRaw(prop.perspectives?.content)
        ),
      });
    }
    if (prop.testimonials?.length > 0) {
      this.setState({
        testimonials: prop.testimonials,
      });
    }
    /* if (Object.keys(prop.image).length > 0) {this.setState({ coursePhoto: prop.image })} */
    if (prop.domains && prop.domains.length > 0) {
      this.setState({
        preSelectedDomains: prop.domains,
        selectedDomains: prop.domains,
      });
    }
  };

  getCourseById = async (id) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const course = await API.get(
        `Courses/${id}` /* , {
          params: { userId: userId, access_token: accessToken }
        } */
      );

      if (course.status === 200) {
        this.setState(
          {
            course: course.data,
          },
          () => this.populateForm(course.data)
        );
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  updateTestimonialInputValue = async (e, index) => {
    const target = e.target;
    const name = target.name;
    this.setState(({ testimonials }) => ({
      testimonials: [
        ...testimonials.slice(0, index),
        {
          ...testimonials[index],
          [name]: target.value,
        },
        ...testimonials.slice(index + 1),
      ],
    }));
  };

  addCourse = (e) => {
    // this.setState({
    //   loading: true
    // });

    e.preventDefault();

    const request = {
      id: this.state.id,
      title: this.state.title,
      description: this.state.description,
      /* image: this.state.coursePhoto.container, */
      prerequisite: this.state.skills,
      price: this.state.price,
      /* discount: this.state.offer, */
      courseCreator: this.state.courseCreator,
      difficulty: this.state.level,
      syllabus: convertToRaw(this.state.editorState.getCurrentContent()),
      domains: this.state.selectedDomains,
      objectifs: {
        content: convertToRaw(
          this.state.objectifsEditorState.getCurrentContent()
        ),
        image: this.state.course.objectifs?.image,
      },
      perspectives: {
        content: convertToRaw(
          this.state.perspectivesEditorState.getCurrentContent()
        ),
        image: this.state.course.perspectives?.image,
      },
      categoryId: this.state.categoryId,
      forWho: this.state.forWho,

      profileId: localStorage.getItem("WeCodeLand_user"),
      testimonials: this.state.testimonials,
    };
    if (
      this.state.photoChanged &&
      this.state.coursePhoto &&
      this.state.coursePhoto.container &&
      Object.keys(this.state.coursePhoto.container).length > 0
    ) {
      request.image = this.state.coursePhoto.container;
    }
    if (
      this.state.perspectivesPhotoChanged &&
      this.state.perspectivesPhoto &&
      this.state.perspectivesPhoto.container &&
      Object.keys(this.state.perspectivesPhoto.container).length > 0
    ) {
      request.perspectives.image = this.state.perspectivesPhoto.container;
    }
    if (
      this.state.objectifsPhotoChanged &&
      this.state.objectifsPhoto &&
      this.state.objectifsPhoto.container &&
      Object.keys(this.state.objectifsPhoto.container).length > 0
    ) {
      request.objectifs.image = this.state.objectifsPhoto.container;
    }
    if (this.state.file.name.length > 0) {
      request.file = this.state.file;
    }

    API.patch(`${SERVERDIR}Courses/editCourse`, request)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.status === 200) {
          console.log("SUBMITTED SUCCESSFULLY");
          notify({ type: "success", message: "success" }, this);
          this.props.history.push(BASEDIR + "/admin/courseManagement");
        } else {
          this.setState({ modalConfirmAction: false });
          throw res.status;
        }
      })
      .catch((error) => {
        // this.setState({
        //   loading: false
        // });
        console.log("error:", error);
        const {
          response: {
            data: {
              error: { message },
            },
          } = {},
        } = error;
        notify(
          {
            type: "danger",
            message:
              message === "Authorization Required"
                ? "You are not able to add a course"
                : message,
          },
          this
        );
        this.setState({ modalConfirmAction: false });
      });
  };

  //** UPLOAD COURSE IMAGE */
  photoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { coursePhoto } = this.state;
    let uploadedPhoto;
    if (e.target.name === "coursePhoto") {
      coursePhoto.preview = URL.createObjectURL(file);
      coursePhoto.data = file;
      uploadedPhoto = {
        file: coursePhoto,
        name: "coursePhoto",
      };
    }

    // const file = this.state.coursePhoto
    const fileData = new FormData();
    fileData.append("file", uploadedPhoto.file.data);

    try {
      let imageProfile = await API.post(
        `${SERVERDIR}Containers/img/upload`,
        fileData
      );
      if (imageProfile.status === 200) {
        // notify({ type: 'success', message: "success" }, this);
        uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
        await this.setState({
          [uploadedPhoto.name]: uploadedPhoto.file,
          photoChanged: true,
        });
      } else {
        throw imageProfile.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      if (message.includes("contentType"))
        notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey: Date.now(),
      });
    } finally {
    }
  };

  //** UPLOAD Objectifs IMAGE */
  objectifsPhotoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { objectifsPhoto } = this.state;
    let uploadedPhoto;
    if (e.target.name === "objectifsPhoto") {
      objectifsPhoto.preview = URL.createObjectURL(file);
      objectifsPhoto.data = file;
      uploadedPhoto = {
        file: objectifsPhoto,
        name: "objectifsPhoto",
      };
    }

    // const file = this.state.objectifsPhoto
    const fileData = new FormData();
    fileData.append("file", uploadedPhoto.file.data);

    try {
      let imageProfile = await API.post(
        `${SERVERDIR}Containers/img/upload`,
        fileData
      );
      if (imageProfile.status === 200) {
        // notify({ type: 'success', message: "success" }, this);
        uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
        await this.setState({
          [uploadedPhoto.name]: uploadedPhoto.file,
          objectifsPhotoChanged: true,
        });
      } else {
        throw imageProfile.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      if (message.includes("contentType"))
        notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey: Date.now(),
      });
    } finally {
    }
  };
  //** UPLOAD Perspectives IMAGE */
  perspectivesPhotoChangeHandler = async (e) => {
    const file = e.target.files[0];
    const { perspectivesPhoto } = this.state;
    let uploadedPhoto;
    if (e.target.name === "perspectivesPhoto") {
      perspectivesPhoto.preview = URL.createObjectURL(file);
      perspectivesPhoto.data = file;
      uploadedPhoto = {
        file: perspectivesPhoto,
        name: "perspectivesPhoto",
      };
    }

    // const file = this.state.perspectivesPhoto
    const fileData = new FormData();
    fileData.append("file", uploadedPhoto.file.data);

    try {
      let imageProfile = await API.post(
        `${SERVERDIR}Containers/img/upload`,
        fileData
      );
      if (imageProfile.status === 200) {
        // notify({ type: 'success', message: "success" }, this);
        uploadedPhoto.file.container = imageProfile.data.result.files.file[0];
        await this.setState({
          [uploadedPhoto.name]: uploadedPhoto.file,
          perspectivesPhotoChanged: true,
        });
      } else {
        throw imageProfile.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      if (message.includes("contentType"))
        notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey: Date.now(),
      });
    } finally {
    }
  };

  testimonialPhotoChangeHandler = async (e, index) => {
    const file = e.target.files[0];
    const { testimonials } = this.state;
    let testimonialphoto = testimonials?.[index]?.photo;
    let uploadedPhoto;
    if (e.target.name === `testimonialPhoto${index}`) {
      testimonialphoto.preview = URL.createObjectURL(file);
      testimonialphoto.data = file;
      uploadedPhoto = {
        file: testimonialphoto,
        name: `testimonialPhoto${index}`,
      };
    }

    // const file = this.state.perspectivesPhoto
    const fileData = new FormData();
    fileData.append("file", uploadedPhoto.file.data);

    try {
      let imageProfile = await API.post(
        `${SERVERDIR}Containers/img/upload`,
        fileData
      );
      if (imageProfile.status === 200) {
        console.log("uploadedPhoto: ", imageProfile.data);
        // notify({ type: 'success', message: "success" }, this);
        uploadedPhoto.file.container = imageProfile.data.result.files.file[0];

        this.setState(({ testimonials }) => ({
          testimonials: [
            ...testimonials.slice(0, index),
            {
              ...testimonials[index],
              photo: uploadedPhoto.file,
            },
            ...testimonials.slice(index + 1),
          ],
        }));
      } else {
        throw imageProfile.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      if (message.includes("contentType"))
        notify({ type: "danger", message: "Please upload an image" }, this);
      this.setState({
        inputKey: Date.now(),
      });
    } finally {
    }
  };

  removeTestimonialBlock = (indexToRemove) => {
    let newTestimonials;
    newTestimonials = this.state.testimonials.filter(
      (_, index) => index !== indexToRemove
    );
    this.setState({
      testimonials: newTestimonials,
    });
  };
  addTestimonialBlock = (e) => {
    e.preventDefault();
    let newTestimonials;
    newTestimonials = [...this.state.testimonials];
    newTestimonials.push({
      photo: {
        preview: profilePhoto,
        data: {},
        container: {},
      },
      author: "",
      content: "",
    });
    this.setState({
      testimonials: newTestimonials,
    });
  };

  handelOnUploadFile = async (course) => {
    course.preventDefault();
    const { file } = this.state;
    const originalFileName = course.currentTarget.files[0];
    this.setState({
      isFileUploading: true,
    });

    const formData = new FormData();
    formData.append("file", course.target.files[0]);

    let fileUploaded = await API.post(
      `${SERVERDIR}Container2s/file/upload`,
      formData
    );
    if (fileUploaded.status === 200) {
      this.setState({ isFileUploading: false });
      file.originalFileName = originalFileName.name;
      file.name = fileUploaded.data.result.files.file[0].name;
      this.setState({
        file: file,
        isFileUploading: false,
      });
      notify({ type: "success", message: "file Uploaded" }, this);
    } else {
      throw fileUploaded.status;
    }
  };

  toggleModalConfirmAction = (e) => {
    e.preventDefault();
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction,
    });
  };

  render() {
    const {
      isFileUploading,
      skills,
      course,
      photoChanged,
      objectifsPhotoChanged,
      perspectivesPhotoChanged,
    } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title" style={{ textTransform: "none" }}>
                    Edit Course
                  </h1>
                </div>
              </div>

              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header"></header>
                    <div className="content-body">
                      <form id="myForm" onSubmit={this.addCourse} method="post">
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <img
                              alt=""
                              src={
                                course &&
                                course.image &&
                                Object.keys(course.image).length > 0 &&
                                !photoChanged
                                  ? `${SERVERDIR}containers/${course.image.container}/download/${course.image.name}`
                                  : this.state.coursePhoto.preview
                              }
                            />
                            <div className="profileimg-input">
                              <Input
                                type="file"
                                key={this.state.inputKey}
                                name="coursePhoto"
                                id="exampleFile"
                                onChange={this.photoChangeHandler}
                                style={{ marginTop: "10px" }}
                              />
                            </div>
                          </div>

                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <label
                                htmlFor="imputTitle"
                                style={{ fontWeight: "bold" }}
                              >
                                Title
                              </label>
                              <input
                                type="text"
                                name="title"
                                maxLength="40"
                                onChange={this.updateInputValue}
                                value={this.state.title}
                                className="form-control"
                                id="imputTitle"
                                placeholder="Title"
                                style={{ borderRadius: "4px" }}
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="inputObjective"
                                style={{ fontWeight: "bold" }}
                              >
                                Description
                              </label>
                              <Input
                                type="textarea"
                                rows="5"
                                maxLength="350"
                                cols="50"
                                className="form-control custom-fixed-textarea"
                                name="description"
                                value={this.state.description}
                                onChange={this.updateInputValue}
                                id="inputDescription"
                                placeholder="Tell us about the course"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="form-group">
                            <label
                              htmlFor="inputSyllabus"
                              style={{ fontWeight: "bold" }}
                            >
                              Syllabus
                            </label>
                            <Editor
                              editorState={this.state.editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={this.onEditorStateChange}
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "fontFamily",
                                  "list",
                                  "textAlign",
                                  "colorPicker",
                                  "link",
                                  "remove",
                                  "history",
                                ],
                                fontFamily: {
                                  options: [
                                    "AmpleSoft",
                                    "Mulish",
                                    "Arial",
                                    "Georgia",
                                    "Impact",
                                    "Tahoma",
                                    "Times New Roman",
                                    "Verdana",
                                    "Open Sans",
                                  ],
                                },
                              }}
                            />

                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="inputObjectifs"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Objectifs
                                  </label>
                                  <Editor
                                    editorState={
                                      this.state.objectifsEditorState
                                    }
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={
                                      this.onObjectifsEditorStateChange
                                    }
                                    toolbar={{
                                      options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "colorPicker",
                                        "link",
                                        "remove",
                                        "history",
                                      ],
                                      fontFamily: {
                                        options: [
                                          "AmpleSoftPro",
                                          "Mulish",
                                          "Arial",
                                          "Georgia",
                                          "Impact",
                                          "Tahoma",
                                          "Times New Roman",
                                          "Verdana",
                                          "Open Sans",
                                        ],
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <img
                                  alt=""
                                  src={
                                    course?.objectifs?.image?.name &&
                                    !objectifsPhotoChanged
                                      ? `${SERVERDIR}containers/${course.objectifs?.image?.container}/download/${course.objectifs?.image?.name}`
                                      : this.state.objectifsPhoto.preview
                                  }
                                />
                                <div className="profileimg-input">
                                  <Input
                                    type="file"
                                    key={this.state.inputKey}
                                    name="objectifsPhoto"
                                    id="exampleFile"
                                    onChange={this.objectifsPhotoChangeHandler}
                                    style={{ marginTop: "10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="inputPerspectives"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Perspectives
                                  </label>
                                  <Editor
                                    editorState={
                                      this.state.perspectivesEditorState
                                    }
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={
                                      this.onPerspectivesEditorStateChange
                                    }
                                    toolbar={{
                                      options: [
                                        "inline",
                                        "blockType",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "colorPicker",
                                        "link",
                                        "remove",
                                        "history",
                                      ],
                                      fontFamily: {
                                        options: [
                                          "AmpleSoftPro",
                                          "Mulish",
                                          "Arial",
                                          "Georgia",
                                          "Impact",
                                          "Tahoma",
                                          "Times New Roman",
                                          "Verdana",
                                          "Open Sans",
                                        ],
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <img
                                  alt=""
                                  src={
                                    course?.perspectives?.image?.name &&
                                    !perspectivesPhotoChanged
                                      ? `${SERVERDIR}containers/${course.perspectives?.image?.container}/download/${course.perspectives?.image?.name}`
                                      : this.state.perspectivesPhoto.preview
                                  }
                                />
                                <div className="profileimg-input">
                                  <Input
                                    type="file"
                                    key={this.state.inputKey}
                                    name="perspectivesPhoto"
                                    id="exampleFile"
                                    onChange={
                                      this.perspectivesPhotoChangeHandler
                                    }
                                    style={{ marginTop: "10px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {this.state.testimonials?.map(
                              (testimonial, index) => {
                                return (
                                  <div
                                    className="row"
                                    key={index}
                                    style={{
                                      border: "1px solid black",
                                      padding: "5px",
                                      margin: "5px",
                                    }}
                                  >
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                      <div className="form-group">
                                        <label
                                          htmlFor={`inputTestiomonialContent${index}`}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {`Testiomonial ${index + 1}`}
                                        </label>
                                        <Input
                                          type="textarea"
                                          rows="5"
                                          maxLength="350"
                                          cols="50"
                                          className="form-control custom-fixed-textarea"
                                          name="content"
                                          value={testimonial.content}
                                          onChange={(e) =>
                                            this.updateTestimonialInputValue(
                                              e,
                                              index
                                            )
                                          }
                                          id={`inputTestiomonialContent${index}`}
                                          placeholder="Testiomonial content"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor={`inputTestiomonialAuthor${index}`}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {`Author ${index + 1}`}
                                        </label>
                                        <Input
                                          type="textarea"
                                          rows="5"
                                          maxLength="350"
                                          cols="50"
                                          className="form-control custom-fixed-textarea"
                                          name="author"
                                          value={testimonial.author}
                                          onChange={(e) =>
                                            this.updateTestimonialInputValue(
                                              e,
                                              index
                                            )
                                          }
                                          id={`inputTestiomonialAuthor${index}`}
                                          placeholder="Testiomonial author"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                      <img
                                        alt=""
                                        src={`${SERVERDIR}containers/${testimonial.photo.container?.container}/download/${testimonial.photo.container.name}`}
                                      />
                                      <div className="profileimg-input">
                                        <Input
                                          type="file"
                                          key={this.state.inputKey}
                                          name={`testimonialPhoto${index}`}
                                          id="exampleFile"
                                          onChange={(e) =>
                                            this.testimonialPhotoChangeHandler(
                                              e,
                                              index
                                            )
                                          }
                                          style={{ marginTop: "10px" }}
                                        />
                                      </div>
                                    </div>
                                    {index > 0 && (
                                      <Button
                                        color="primary"
                                        size="sm"
                                        style={{ margin: "10px 5px" }}
                                        onClick={() =>
                                          this.removeTestimonialBlock(index)
                                        }
                                      >
                                        {" "}
                                        Remove testimonial
                                      </Button>
                                    )}
                                  </div>
                                );
                              }
                            )}
                            <Button
                              color="secondary"
                              size="sm"
                              style={{ margin: "10px 5px" }}
                              onClick={this.addTestimonialBlock}
                            >
                              {" "}
                              Add another testimonial
                            </Button>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="imputTitle"
                                style={{ fontWeight: "bold" }}
                              >
                                Price per Person (TND)
                              </label>
                              <input
                                type="number"
                                min="0"
                                name="price"
                                onChange={this.updateInputValue}
                                value={this.state.price}
                                className="form-control"
                                id="imputTitle"
                                placeholder="Insert price here"
                                style={{ borderRadius: "4px" }}
                              />
                            </div>
                            {/* <div className="form-group col-md-3">
                              <label htmlFor="imputTitle" style={{ fontWeight: "bold" }}>Offer percentage</label>
                              <input type="number" min="0" max="100" name="offer" onChange={this.updateInputValue} value={this.state.offer} className="form-control" id="imputTitle" placeholder="Insert a percentage here" style={{ borderRadius: "4px" }}  />
                            </div> */}
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="imputCourseCreator"
                                style={{ fontWeight: "bold" }}
                              >
                                Created by (optional)
                              </label>
                              <input
                                type="text"
                                name="courseCreator"
                                maxLength="40"
                                onChange={this.updateInputValue}
                                value={this.state.courseCreator}
                                className="form-control"
                                id="imputCourseCreator"
                                placeholder="Created by"
                                style={{ borderRadius: "4px" }}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="imputType"
                                style={{ fontWeight: "bold" }}
                              >
                                Course Level
                              </label>
                              <select
                                id="imputType"
                                name="level"
                                className="form-control"
                                style={{ borderRadius: "4px" }}
                                onChange={this.updateInputValue}
                                value={this.state.level}
                              >
                                {levelList.map((data, key) => {
                                  return (
                                    <option value={data} key={key}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label
                                htmlFor="inputFile"
                                style={{ fontWeight: "bold" }}
                              >
                                Attachment (optional)
                              </label>
                              <Input
                                type="file"
                                name="file"
                                id="inputFile"
                                onChange={this.handelOnUploadFile}
                                style={{ marginTop: "10px" }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputSkills"
                                style={{ fontWeight: "bold" }}
                              >
                                Required Skills
                              </label>
                              <TagsInput
                                key={skills.length}
                                placeholder="Enter a required skill and press the + button to add it"
                                selectedTags={this.selectedSkills}
                                tags={skills}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputDomains"
                                style={{ fontWeight: "bold" }}
                              >
                                Domain(s)
                              </label>
                              <Multiselect
                                selectedValues={this.state.preSelectedDomains}
                                options={this.state.domainsList} // Options to display in the dropdown
                                onSelect={this.updateSelectedDomains} // Function will trigger on select event
                                onRemove={this.updateSelectedDomains} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                ref={this.multiselectRef}
                                placeholder="Select domain(s)"
                                avoidHighlightFirstOption={true}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputCategoryId"
                                style={{ fontWeight: "bold" }}
                              >
                                Category
                              </label>
                              <select
                                id="imputCategoryId"
                                name="categoryId"
                                className="form-control"
                                style={{ borderRadius: "4px" }}
                                onChange={this.updateInputValue}
                                value={this.state.categoryId}
                                placeholder="Category"
                              >
                                {this.state.categoriesList?.map(
                                  ({ id, name }) => {
                                    return (
                                      <option value={id} key={id}>
                                        {name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputForWho"
                                style={{ fontWeight: "bold" }}
                              >
                                For who
                              </label>
                              <input
                                type="text"
                                name="forWho"
                                maxLength="400"
                                onChange={this.updateInputValue}
                                value={this.state.forWho}
                                className="form-control"
                                id="imputForWho"
                                placeholder="Created by"
                                style={{ borderRadius: "4px" }}
                              />
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                this.props.history.push(
                                  BASEDIR + "/admin/courseManagement"
                                )
                              }
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "#444444",
                                BoxShadow: "0px 0px 12px",
                                width: "40%",
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={isFileUploading}
                              onClick={this.toggleModalConfirmAction}
                              className="btn btn-primary"
                              style={{ width: "40%" }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          isOpen={this.state.modalConfirmAction}
          toggle={this.toggleModalConfirmAction}
        >
          <ModalHeader>Edit course</ModalHeader>
          <ModalBody>
            <h3>Are you sure you want to save these changes</h3>
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              form="myForm"
              key="submit"
              htmltype="submit"
            >
              {"Confirm"}
            </Button>{" "}
            <Button
              className="save-button"
              color="secondary"
              onClick={this.toggleModalConfirmAction}
            >
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default EditCourse;
