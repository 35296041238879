import React, { Component } from "react";
import API from "../../../helpers/api.jsx";
import ReactGA from "react-ga";
import { notify } from "../../../helpers/common.jsx";
import { Col, Row, Input, Button } from "reactstrap";
var BASEDIR = process.env.REACT_APP_BASEDIR;

export default class ManagementStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: 0,
      insctructors: 0,
      hosts: 0,
      ongoingSessions: 0,
    };
  }

  AddStatisticManagement = (e) => {
    e.preventDefault();
    e.target.reset();

    this.setState({
      loading: true,
    });
    const request = {
      students: this.state.AccomplishedStudents,
      insctructors: this.state.InstructorsOnThePlatform,
      hosts: this.state.GratiousHostsOnThePlatform,
      ongoingSessions: this.state.OnDemandOnGoingSessions,
    };

    API.post(`/Statistics`, request)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.status === 200) {
          ReactGA.event({
            category: "Statistic",
            action: "Add Statistic Management",
          });
          notify({ type: "success", message: "success" }, this);
          this.props.history.push(BASEDIR + "/admin/managementStatistics");
        } else {
          throw res.status;
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        const {
          response: {
            data: {
              error: { message },
            },
          } = {},
        } = error;
        notify({ type: "Failed", message: "Failed" }, this);
      });
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title" style={{ textTransform: "none" }}>
                    Statistic Management{" "}
                  </h1>
                </div>
              </div>

              <div className="row margin-0">
                <div className="col-12">
                  <section className="box ">
                    <header className="panel_header"></header>
                    <div className="content-body">
                      <form
                        id="myForm"
                        onSubmit={this.AddStatisticManagement}
                        method="post"
                      >
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputAccomplishedStudents"
                                style={{ fontWeight: "bold" }}
                              >
                                Accomplished Students{" "}
                              </label>
                              <input
                                type="number"
                                name=" AccomplishedStudents"
                                maxLength="400"
                                onChange={(e) =>
                                  this.setState({
                                    AccomplishedStudents: e.target.value,
                                  })
                                }
                                value={this.AccomplishedStudents}
                                className="form-control"
                                id="imputStudents"
                                style={{ borderRadius: "4px" }}
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputInstructorsOnThePlatform"
                                style={{ fontWeight: "bold" }}
                              >
                                Instructors On The Platorm{" "}
                              </label>
                              <input
                                type="number"
                                name="InstructorsOnThePlatform"
                                maxLength="400"
                                onChange={(e) =>
                                  this.setState({
                                    InstructorsOnThePlatform: e.target.value,
                                  })
                                }
                                value={this.InstructorsOnThePlatform}
                                className="form-control"
                                id="imputInstructors"
                                style={{ borderRadius: "4px" }}
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputEmail"
                                style={{ fontWeight: "bold" }}
                              >
                                Gratious Hosts On The Platform
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="imputHosts"
                                name="GratiousHostsOnThePlatform"
                                value={this.GratiousHostsOnThePlatform}
                                onChange={(e) =>
                                  this.setState({
                                    GratiousHostsOnThePlatform: e.target.value,
                                  })
                                }
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label
                                htmlFor="imputOnGoingSessions"
                                style={{ fontWeight: "bold" }}
                              >
                                On going Sessions
                              </label>
                              <input
                                type="number"
                                name="OnDemandOnGoingSessions "
                                maxLength="30"
                                onChange={(e) =>
                                  this.setState({
                                    OnDemandOnGoingSessions: e.target.value,
                                  })
                                }
                                value={this.OnDemandOnGoingSessions}
                                className="form-control"
                                id="imputOnGoingSessions "
                                style={{ borderRadius: "4px" }}
                                required
                              />
                            </div>
                          </div>

                          <div
                            className="row"
                            style={{ justifyContent: "end" }}
                          >
                            <Button
                              form="myForm"
                              key="submit"
                              htmltype="submit"
                              style={{ borderRadius: "8px" }}
                              color="primary"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
