import React, { useState, useEffect } from "react";
import {
  Button,
  div,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import API from "./../../helpers/api.jsx";

const BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

export default function NewSessionModal({ isOpen, hideModal }) {
  const [state, setState] = useState({});
  const [suggestionList, setLists] = useState({});
  const [selectionValue, setSelectionValue] = useState("");
  const [selectedPrevSession, setSelectedPrevSession] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [disabledNext, setDisabledNext] = useState(true);
  useEffect(() => {
    selectionValue === "1"
      ? setDisabledNext(false)
      : selectionValue === "2" && selectedPrevSession.length
      ? setDisabledNext(false)
      : selectionValue === "3" && selectedCourse.length
      ? setDisabledNext(false)
      : setDisabledNext(true);
  }, [selectionValue, selectedPrevSession, selectedCourse]);

  const getSessionList = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const List = await API.get(`/Sessions/list?access_token=${accessToken}`);
    if (List.status === 200) {
      setLists(List.data);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    getSessionList();
  }, []);

  const handleNext = () => {
    if (selectionValue == "2") {
      window.location.href = `${BASEDIR}/admin/newSession/edit/${selectedPrevSession}`;
    } else if (selectionValue == "3" && selectedCourse.length) {
      window.location.href = `${BASEDIR}/admin/newSession/create/${selectedCourse}`;
    } else if (selectionValue == "1") {
      window.location.href = `${BASEDIR}/admin/newCourse`;
    }
    hideModal();
  };

  return (
    <Modal isOpen={isOpen} backdrop={"static"} keyboard={false}>
      <ModalHeader>
        <span>Adding a new session/course</span>
      </ModalHeader>
      <ModalBody>
        <h4>Please Select one option</h4>
        <select
          onChange={(value) => {
            setSelectionValue(value.target.value);
          }}
          value={selectionValue}
          size="large"
          style={{ width: "100%" }}
        >
          <option value="" disabled hidden>
            No option Selected
          </option>
          <option value="1">Create your own course </option>
          <option value="2">Create session from a previous session</option>
          <option value="3">Create session from an existing course</option>
        </select>
        {selectionValue === "2" ? (
          <>
            <h4 style={{ marginTop: 30 }}>Pevious Session</h4>
            <p>Please select a previously taught session</p>
            <select
              value={selectedPrevSession}
              onChange={(value) => setSelectedPrevSession(value.target.value)}
              size="large"
              style={{ width: "100%" }}
            >
              <option value="" disabled hidden>
                No session selected{" "}
              </option>
              {suggestionList.sessions.map((sessionOption) => {
                return (
                  <option value={sessionOption.id} key={sessionOption.id}>
                    {sessionOption.title}{" "}
                  </option>
                );
              })}
            </select>
          </>
        ) : selectionValue === "3" ? (
          <>
            <h4 style={{ marginTop: 30 }}>Previous course</h4>
            <p>Please select an existing course</p>
            <select
              value={selectedCourse}
              onChange={(value) => setSelectedCourse(value.target.value)}
              size="large"
              style={{ width: "100%" }}
            >
              <option value="" disabled hidden>
                No course selected{" "}
              </option>
              {suggestionList.courses.map((courseOption) => {
                return (
                  <option value={courseOption.id} key={courseOption.id}>
                    {courseOption.title}{" "}
                  </option>
                );
              })}
            </select>
          </>
        ) : (
          ""
        )}
      </ModalBody>
      <ModalFooter>
        <div justify="end" style={{ marginTop: 20 }}>
          <Button
            onClick={hideModal}
            type="text"
            id="add-button"
            style={{ width: "auto", margin: "2px", backgroundColor: "#9e9e9e" }}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            id="add-button"
            onClick={handleNext}
            disabled={disabledNext}
          >
            Next
          </Button>{" "}
        </div>{" "}
      </ModalFooter>
    </Modal>
  );
}
