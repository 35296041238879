import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import API from "./../../helpers/api.jsx";
import { notify } from "./../../helpers/common.jsx";
import empty_pic_course from "./../../assets/img/course-default.png";
import NotificationAlert from "react-notification-alert";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NumericInput from "react-numeric-input";
//import TimePicker from "react-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import classnames from "classnames";

import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";

import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;
const levelList = ["Beginner", "Intermediate", "Advanced"];
const format = "HH:mm";
const now = moment().hour(0).minute(0);

class EditSession extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sessionId: this.props.match.params && this.props.match.params.id,
      modalWarning: false,
      session: {},
      rooms: [],
      currentRooms: [],
      limit: 4,
      count: 0,
      active: 1,
      title: "",
      description: "",
      price: 0,
      offer: 0,
      minimumPlaces: 0,
      maximumPlaces: 0,
      duration: 0.0,
      cancellationDays: 0,
      activeTab: "1",
      startTime: "10:00",
      endTime: "10:00",
      level: "Beginner",
      file: {
        originalFileName: "",
        name: "",
      },
      isFileUploading: false,
      coursePhoto: {
        preview: empty_pic_course,
        data: {},
        container: {},
      },
      editorState: EditorState.createEmpty(),
      skills: [],
      domainsList: [],
      selectedDomains: [],
      value: "",
      suggestions: [],
      allSuggestions: {},
      selectedCourseId: "",
      modalDate: false,
      backClicked: false,
      dateRangeValue: moment.range(today.clone(), today.clone()),
      prevStartDate: moment.range(today.clone(), today.clone()).start,
      prevEndDate: moment.range(today.clone(), today.clone()).end,
      isOnline: false,
      sessionLink: "",
    };
    this.notificationAlert = React.createRef();
  }

  componentWillMount = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    await this.getSessionDetails(
      this.props.match.params && this.props.match.params.id
    );
    this.toggleModalWarning();
  };
  toggleModalWarning = () => {
    this.setState({ modalWarning: !this.state.modalWarning });
  };
  firstSubmit = async (event) => {
    event.preventDefault();
    if (
      !this.state.backClicked ||
      (this.state.backClicked &&
        this.state.prevStartDate !==
          this.state.dateRangeValue.start.format("YYYY-MM-DD")) ||
      (this.state.backClicked &&
        this.state.prevEndDate !==
          this.state.dateRangeValue.end.format("YYYY-MM-DD"))
    ) {
      await this.getClassrooms(1);
    }
    this.setState({
      activeTab: "2",
    });
  };
  goBack = (event) => {
    event.preventDefault();
    this.setState({
      activeTab: "1",
      backClicked: true,
    });
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    this.showClassroomPage(selectedPage + 1);
    window.scrollTo(0, 0);
  };

  handleClassroomCheck = (id) => {
    const { currentRooms, rooms } = this.state;
    console.log(
      "currentRooms.indexOf(x => x.id === id)",
      currentRooms.findIndex((x) => x.id === id),
      id
    );
    if (currentRooms.findIndex((x) => x.id === id) > -1) {
      if (rooms[rooms.findIndex((x) => x.id === id)].checked === "") {
        rooms[rooms.findIndex((x) => x.id === id)].checked = id;
      } else {
        rooms[rooms.findIndex((x) => x.id === id)].checked = "";
      }
    }
    this.setState({
      rooms: rooms,
    });
  };

  myFormat = (num) => {
    return num + " days";
  };
  onSelectDateRange = (dateRangeValue, states) => {
    this.setState({ dateRangeValue, states });
  };

  toggleDateRangePicker = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  renderSelectionValue = () => {
    /* if(!(this.state.dateRangeValue.start.format("YYYY-MM-DD") === this.state.dateRangeValue.end.format("YYYY-MM-DD") && this.state.dateRangeValue.start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")))
    { */
    console.log(this.state.dateRangeValue.start);

    return (
      <div>
        <h5>
          {this.state.dateRangeValue.start.format("YYYY-MM-DD")}
          {" - "}
          {this.state.dateRangeValue.end.format("YYYY-MM-DD")}
        </h5>
      </div>
    ); /* }
    else {
      return null;
    } */
  };

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        console.log(
          "this.state.editorState: ",
          convertToRaw(this.state.editorState.getCurrentContent())
        );
      }
    );
  };

  convertEditorContentToHtml = (editorContent) => {
    return draftToHtml(convertToRaw(editorContent));
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getClassrooms = async (key) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    /* const limit =this.state.limit;
      const skip =((key - 1) * this.state.limit); */
    try {
      const allSuggestions = await API.get(`Rooms/list`, {
        params: {
          isOnline: this.state.isOnline,
          start: moment(this.state.dateRangeValue.start).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          end: moment(this.state.dateRangeValue.end).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          limit: 99999,
          skip: 0,
          access_token: accessToken,
        },
      });
      this.setState({
        prevStartDate: this.state.dateRangeValue.start.format("YYYY-MM-DD"),
        prevEndDate: this.state.dateRangeValue.end.format("YYYY-MM-DD"),
      });
      if (allSuggestions.status === 200) {
        this.setState({
          rooms: allSuggestions?.data?.rooms?.map((v) => ({
            ...v,
            checked: "",
          })),
          active: key,
        });
        const pageCount = Math.ceil(
          allSuggestions.data.count / this.state.limit
        );
        this.setState({ count: pageCount }, () => this.showClassroomPage(1));
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  showClassroomPage = (page) => {
    const { limit, rooms } = this.state;
    this.setState({
      currentRooms: rooms.slice((page - 1) * limit, (page - 1) * limit + limit),
    });
  };

  updateInputValue = async (e) => {
    const target = e.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  };
  updateMinPlacesInputValue = async (e) => {
    const target = e.target;
    this.setState({
      minimumPlaces: target.value,
    });
    if (this.state.maximumPlaces < target.value) {
      this.setState({
        maximumPlaces: target.value,
      });
    }
  };
  updateDurationInputValue = async (valueAsNumber) => {
    this.setState({
      duration: valueAsNumber,
    });
  };
  updateCancellationDaysInputValue = async (valueAsNumber) => {
    this.setState({
      cancellationDays: valueAsNumber,
    });
  };

  handleChangeStartTime = (time) => {
    this.setState({
      startTime: moment(time).format("HH:mm"),
    });
  };

  handleChangeEndTime = (time) => {
    this.setState({
      endTime: moment(time).format("HH:mm"),
    });
  };

  getSessionDetails = async (sessionId) => {
    this.setState({
      loading: true,
    });
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    try {
      const session = await API.get(`Sessions/details/${sessionId}`, {
        params: { access_token: accessToken },
      });

      if (session.status === 200) {
        console.log("session.data", session.data);
        this.setState(
          {
            session: session.data,
          },
          () => this.populateForm(session.data)
        );
      }
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  populateForm = (prop) => {
    prop = prop ? prop : {};
    if (prop.minPlace !== this.state.minimumPlaces) {
      this.setState({ minimumPlaces: prop.minPlace });
    }
    if (prop.maxPlace !== this.state.maximumPlaces) {
      this.setState({ maximumPlaces: prop.maxPlace });
    }
    if (prop.duration !== this.state.duration) {
      this.setState({ duration: prop.duration });
    }
    if (moment.utc(prop.startTime).format("HH:mm") !== this.state.startTime) {
      this.setState({ startTime: moment.utc(prop.startTime).format("HH:mm") });
    }
    if (moment.utc(prop.endTime).format("HH:mm") !== this.state.endTime) {
      this.setState({ endTime: moment.utc(prop.endTime).format("HH:mm") });
    }
    if (
      !(
        moment(prop.end).isSame(moment(), "day") &&
        moment(prop.start).isSame(moment(), "day")
      )
    ) {
      this.setState({
        dateRangeValue: moment.range(moment(prop.start), moment(prop.end)),
      });
    }
    if (prop.TimeWindowForCancel !== this.state.cancellationDays) {
      this.setState({ cancellationDays: prop.TimeWindowForCancel });
    }
    this.setState({ title: prop.title || "" });
    if (prop.isOnline) {
      this.setState({
        sessionLink:
          prop.onlineSessionLink && prop.onlineSessionLink.length > 0
            ? prop.onlineSessionLink
            : "",
      });
    }
    this.setState({ isOnline: prop.isOnline || false });
    this.setState({ description: prop.description || "" });
    this.setState({ price: prop.price || 0 });
    this.setState({ offer: prop.discount || 0 });
    this.setState({ level: prop.difficulty || "" });
    if (
      prop &&
      prop.sessionCourse &&
      prop.sessionCourse &&
      prop.sessionCourse.domains &&
      prop.sessionCourse.domains.length > 0
    )
      this.setState({
        selectedDomains:
          prop &&
          prop.sessionCourse &&
          prop.sessionCourse &&
          prop.sessionCourse.domains,
      });
    this.setState({ skills: prop.prerequisite });
    if (
      prop &&
      prop.sessionCourse &&
      prop.sessionCourse.syllabus &&
      Object.keys(prop.sessionCourse.syllabus).length > 0
    ) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(
            prop && prop.sessionCourse && prop.sessionCourse.syllabus
          )
        ),
      });
    }
    if (
      prop &&
      prop.sessionCourse &&
      prop.sessionCourse.image &&
      Object.keys(prop.sessionCourse.image).length > 0
    ) {
      this.setState({ coursePhoto: prop.image });
    }
    if (
      prop &&
      prop.sessionCourse &&
      prop.sessionCourse.file &&
      Object.keys(prop.sessionCourse.file).length > 0 &&
      prop.sessionCourse.file.originalFileName
    ) {
      this.setState({ file: prop.file });
    }
  };

  addSession = (e) => {
    e.preventDefault();
    /* const selectedRooms = this.state.rooms.filter(a => a.checked!== "").map(a => ({id: a.id}));
    console.log(selectedRooms);
   if(selectedRooms.length === 0 ){
    notify({ type: 'danger', message: "Please select a classroom" }, this);
   }
   else{ */
    const request = {
      /* session : { */
      sessionId: this.state.sessionId,
      minPlace: this.state.minimumPlaces,
      maxPlace: this.state.maximumPlaces,
      duration: this.state.duration,
      startTime:
        moment(this.state.dateRangeValue.start).format("YYYY-MM-DDT") +
        this.state.startTime,
      endTime:
        moment(this.state.dateRangeValue.end).format("YYYY-MM-DDT") +
        this.state.endTime,
      TimeWindowForCancel: this.state.cancellationDays,
      start: moment(this.state.dateRangeValue.start).format(
        "YYYY-MM-DDT00:00:00.000"
      ),
      end: moment(this.state.dateRangeValue.end).format(
        "YYYY-MM-DDT00:00:00.000"
      ),
      isOnline: this.state.isOnline,
      /* title: this.state.title,
      description: this.state.description,
      prerequisite: this.state.skills,
      price: this.state.price,
      discount: this.state.offer,
      difficulty: this.state.level,
      teacherId: localStorage.getItem('WeCodeLand_user'),
      courseId: this.state.session && this.state.session.sessionCourse && this.state.session.sessionCourse.id */
      /* }, */
      /* room: selectedRooms,
      domains: this.state.selectedDomains */
    };
    /* if(this.state.session && this.state.session.sessionCourse && this.state.session.sessionCourse.image && Object.keys(this.state.session.sessionCourse.image).length > 0){
      request.session.SessionImage = this.state.session.sessionCourse.image
    } */
    /* const data = [request]; */
    if (this.state.isOnline) {
      request.onlineSessionLink = this.state.sessionLink;
    }

    API.patch(`${SERVERDIR}Sessions/editSession`, request)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.status === 200) {
          console.log("SUBMITTED SUCCESSFULLY");
          notify({ type: "success", message: "success" }, this);
          this.props.history.push(BASEDIR + "/admin/sessionManagement/1");
        } else {
          throw res.status;
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log("error:", error);
        const {
          response: {
            data: {
              error: { message },
            },
          } = {},
        } = error;
        notify(
          {
            type: "danger",
            message:
              message === "Authorization Required"
                ? "You are not able to add a session"
                : message,
          },
          this
        );
      });

    /*  } */
  };

  /* onlineSwitchHandler=(e)=>{
    this.setState({
      isOnline : e.target.checked
    },()=>this.getClassrooms(1))

  } */

  render() {
    const { currentRooms, session, isFileUploading, skills, value, rooms } =
      this.state;
    const roomInputProps = {
      placeholder: "No Room selected",
      value,
      onChange: this.onChange,
    };
    return (
      <div id="newSession">
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Nav id="register-tabs" className="justify-content-center" tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "1",
                "custom-nav-link": true,
              })}
            >
              1.Founder Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === "2",
                "custom-nav-link": true,
              })}
            >
              2.Startup information
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="content">
              <Row>
                <Col xs={12} md={12}>
                  <div className="page-title">
                    <div className="float-left">
                      <h1 className="title" style={{ textTransform: "none" }}>
                        Edit Session
                      </h1>
                    </div>
                  </div>

                  <div className="row margin-0">
                    <div className="col-12">
                      <section className="box ">
                        <header className="panel_header">
                          <h2 className="title float-left secondary-title">
                            Session informations
                          </h2>
                        </header>
                        <div className="content-body">
                          <form onSubmit={this.addSession}>
                            <div>
                              {/* <CustomInput type="switch" checked={this.state.isOnline} name="isOnline" id="isOnline" label={this.state.isOnline? "Online" : "On-site"} bsSize="lg" onChange={this.onlineSwitchHandler}/> */}
                              {this.state.isOnline && (
                                <div className="mb-4">
                                  <label
                                    htmlFor="inputMinPlaces"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Session link
                                  </label>
                                  <input
                                    type="text"
                                    min="1"
                                    name="sessionLink"
                                    onChange={this.updateInputValue}
                                    value={this.state.sessionLink}
                                    className="form-control"
                                    id="sessionLink"
                                    placeholder="Session link"
                                    style={{ borderRadius: "4px" }}
                                    required={this.state.isOnline}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="row">
                              <div className="form-group col-md-3">
                                <label
                                  htmlFor="inputMinPlaces"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Minimum places
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  name="minimumPlaces"
                                  onChange={this.updateMinPlacesInputValue}
                                  value={this.state.minimumPlaces}
                                  className="form-control"
                                  id="inputMinPlaces"
                                  placeholder="Minimum places"
                                  style={{ borderRadius: "4px" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label
                                  htmlFor="inputMaxplaces"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Maximum places
                                </label>
                                <input
                                  type="number"
                                  min={
                                    this.state.minimumPlaces > 0
                                      ? this.state.minimumPlaces
                                      : "1"
                                  }
                                  name="maximumPlaces"
                                  onChange={this.updateInputValue}
                                  value={this.state.maximumPlaces}
                                  className="form-control"
                                  id="inputMaxplaces"
                                  placeholder="Maximum places"
                                  style={{ borderRadius: "4px" }}
                                  required
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label
                                  htmlFor="inputDuration"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Duration
                                </label>
                                <NumericInput
                                  min={0.0}
                                  onChange={this.updateDurationInputValue}
                                  value={this.state.duration}
                                  className="form-control"
                                  id="inputDuration"
                                  placeholder="Duration in hours"
                                  style={{ borderRadius: "4px" }}
                                  required
                                  step={0.1}
                                  precision={2}
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label
                                  htmlFor="inputStartDate"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Start date - Finish date
                                </label>
                                <div>{this.renderSelectionValue()}</div>
                                <div>
                                  <input
                                    className="btn btn-primary"
                                    type="button"
                                    value="Open Calendar"
                                    onClick={this.toggleDateRangePicker}
                                    style={{ borderRadius: "7px" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-3">
                                <label
                                  htmlFor="imputTitle"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Start hour
                                </label>

                                <TimePicker
                                  showSecond={false}
                                  defaultValue={now}
                                  popupStyle={{
                                    width: "200px",
                                    maxWidth: "200px",
                                  }}
                                  style={{
                                    borderRadius: "4px",
                                    width: "200px",
                                    display: "block",
                                  }}
                                  onChange={this.handleChangeStartTime}
                                  value={moment(this.state.startTime, "HH:mm")}
                                  format={format}
                                  use12Hours={false}
                                  inputReadOnly
                                  allowEmpty={false}
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label
                                  htmlFor="imputTitle"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Finish hour
                                </label>

                                <TimePicker
                                  showSecond={false}
                                  defaultValue={now}
                                  popupStyle={{
                                    width: "200px",
                                    maxWidth: "200px",
                                  }}
                                  style={{
                                    borderRadius: "4px",
                                    width: "200px",
                                    display: "block",
                                  }}
                                  onChange={this.handleChangeEndTime}
                                  value={moment(this.state.endTime, "HH:mm")}
                                  format={format}
                                  use12Hours={false}
                                  inputReadOnly
                                  allowEmpty={false}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  htmlFor="inputCancellation"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Days allowed before cancellation
                                </label>
                                <NumericInput
                                  min={0}
                                  onChange={
                                    this.updateCancellationDaysInputValue
                                  }
                                  value={this.state.cancellationDays}
                                  className="form-control"
                                  id="inputCancellation"
                                  placeholder="days before cancellation"
                                  style={{ borderRadius: "4px" }}
                                  required
                                  format={this.myFormat}
                                />
                              </div>
                            </div>
                            <h3 className="secondary-title">Course chosen</h3>
                            <br />
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <img
                                  alt=""
                                  src={
                                    session &&
                                    session.sessionCourse &&
                                    session.sessionCourse.image &&
                                    Object.keys(session.sessionCourse.image)
                                      .length > 0
                                      ? `${SERVERDIR}containers/${session.sessionCourse.image.container}/download/${session.sessionCourse.image.name}`
                                      : this.state.coursePhoto.preview
                                  }
                                />
                                {/* <div className="profileimg-input">
                                <Input type="file" name="coursePhoto" id="exampleFile" onChange={this.photoChangeHandler} style={{ marginTop: '10px' }} />
                              </div> */}
                              </div>

                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="imputTitle"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Title
                                  </label>
                                  <input
                                    disabled
                                    type="text"
                                    name="title"
                                    maxLength="40"
                                    onChange={this.updateInputValue}
                                    value={this.state.title}
                                    className="form-control"
                                    id="imputTitle"
                                    placeholder="Title"
                                    style={{ borderRadius: "4px" }}
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="inputObjective"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Description
                                  </label>
                                  <Input
                                    disabled
                                    type="textarea"
                                    rows="5"
                                    maxLength="350"
                                    cols="50"
                                    className="form-control custom-fixed-textarea"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.updateInputValue}
                                    id="inputDescription"
                                    placeholder="Tell us about the course"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div className="form-group">
                                <label
                                  htmlFor="inputSyllabus"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Syllabus
                                </label>
                                <Editor
                                  editorState={this.state.editorState}
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                  onEditorStateChange={this.onEditorStateChange}
                                  readOnly
                                />

                                {/* <div dangerouslySetInnerHTML={{ __html: this.convertEditorContentToHtml(this.state.editorState.getCurrentContent())}}/> */}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                              <div className="row">
                                <div className="form-group col-md-3">
                                  <label
                                    htmlFor="inputPrice"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Price per Person (TND)
                                  </label>
                                  <input
                                    disabled
                                    type="number"
                                    min="0"
                                    name="price"
                                    onChange={this.updateInputValue}
                                    value={this.state.price}
                                    className="form-control"
                                    id="inputPrice"
                                    placeholder="Insert price here"
                                    style={{ borderRadius: "4px" }}
                                    required
                                  />
                                </div>
                                <div className="form-group col-md-3">
                                  <label
                                    htmlFor="inputOffer"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Offer percentage
                                  </label>
                                  <input
                                    disabled
                                    type="number"
                                    min="0"
                                    max="100"
                                    name="offer"
                                    onChange={this.updateInputValue}
                                    value={this.state.offer}
                                    className="form-control"
                                    id="inputOffer"
                                    placeholder="Insert a percentage here"
                                    style={{ borderRadius: "4px" }}
                                    required
                                  />
                                </div>
                                <div className="form-group col-md-3">
                                  <label
                                    htmlFor="imputType"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Course Level
                                  </label>
                                  <select
                                    disabled
                                    id="imputType"
                                    name="level"
                                    className="form-control"
                                    style={{ borderRadius: "4px" }}
                                    onChange={this.updateInputValue}
                                    value={this.state.level}
                                    required
                                  >
                                    {levelList?.map((data, key) => {
                                      return (
                                        <option value={data} key={key}>
                                          {data}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className="form-group col-md-3">
                                  <label
                                    htmlFor="inputFile"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Attached file
                                  </label>
                                  {/* <Input disabled type="file" name="file" id="inputFile" onChange={this.handelOnUploadFile} style={{ marginTop: '10px' }} /> */}
                                  {this.state.session &&
                                  this.state.session.sessionCourse &&
                                  this.state.session.sessionCourse.file &&
                                  Object.keys(
                                    this.state.session.sessionCourse.file
                                  ).length > 0 &&
                                  this.state.session.sessionCourse.file.name &&
                                  this.state.session.sessionCourse.file.name
                                    .length > 0 ? (
                                    <span
                                      className="hoverable-text"
                                      style={{
                                        marginTop: "10px",
                                        display: "block",
                                      }}
                                      onClick={() =>
                                        window.open(
                                          `${SERVERDIR}Container2s/file/download/${
                                            this.state.session &&
                                            this.state.session.sessionCourse &&
                                            this.state.session.sessionCourse
                                              .file &&
                                            Object.keys(
                                              this.state.session.sessionCourse
                                                .file
                                            ).length > 0 &&
                                            this.state.session.sessionCourse
                                              .file.name
                                          }`
                                        )
                                      }
                                    >
                                      {this.state.session &&
                                        this.state.session.sessionCourse &&
                                        this.state.session.sessionCourse.file &&
                                        Object.keys(
                                          this.state.session.sessionCourse.file
                                        ).length > 0 &&
                                        this.state.session.sessionCourse.file
                                          .originalFileName}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        marginTop: "10px",
                                        display: "block",
                                      }}
                                    >
                                      No file
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label
                                    htmlFor="imputSkills"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Required Skills
                                  </label>
                                  {skills?.map((tag, index) => (
                                    <li key={index} className="tag">
                                      <span className="tag-title">{tag}</span>
                                    </li>
                                  ))}
                                </div>
                                <div className="form-group col-md-6">
                                  <label
                                    htmlFor="imputDomains"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Domain(s)
                                  </label>
                                  {this.state.selectedDomains?.map(
                                    (tag, index) => (
                                      <li key={index} className="tag">
                                        <span className="tag-title">
                                          {tag.name}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.props.history.push(
                                      BASEDIR + "/admin/sessionManagement/1"
                                    )
                                  }
                                  className="btn btn-primary"
                                  style={{
                                    borderRadius: "7px",
                                    backgroundColor: "#444444",
                                    BoxShadow: "0px 0px 12px",
                                    width: "40%",
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  disabled={isFileUploading}
                                  type="submit"
                                  className="btn btn-primary"
                                  style={{ borderRadius: "7px", width: "40%" }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </section>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
          {/* <TabPane tabId="2">
          <div className="content">
                    <Row>
                        <Col xs={12} md={12}>

                    <div className="page-title">
                        <div className="float-left">
                            <h1 className="title mb-0">New Session</h1>
                        </div>
                    </div>
                    <div className="subtitle mt-0">
                      <h5 className="mt-0">Please select one or multiple classroom preference. When you are done click on the « Publish » button.</h5>
                    </div>


                            
           

                    <div className="col-xl-12">
                            <div className="content-body">    


                            <div className="row">
                                    <div className="col-12 cards-list-container">
                                {
                                    currentRooms.map((room, key) => {
                                        return (
                                            <ClassroomCard key={room.id} room={room} index={key} handleClassroomCheck={this.handleClassroomCheck} selection={true}/>
                                        )
                                    })
                                }

                                    </div>
                                </div>
                            </div></div>



                            {this.state.count>1?
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.count}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    initialPage={this.state.active-1}
                                    forcePage={this.state.active-1}
                                />
                                :null}
                                <div className="row" style={{justifyContent: "center"}}>
                                  <button type="button" onClick={this.goBack} className="btn btn-primary" style={{ borderRadius: "7px",backgroundColor: "#444444", BoxShadow: "0px 0px 12px" , width:"40%"}}>Cancel</button>
                                  <button disabled={isFileUploading} onClick={this.addSession} className="btn btn-primary" style={{ borderRadius: "7px",width:"40%"}}>Publish</button>
                                </div>
                                
                        </Col>

                    </Row>
                </div>
          </TabPane> */}
        </TabContent>
        <Modal isOpen={this.state.isOpen} toggle={this.toggleDateRangePicker}>
          <ModalHeader>Select Start date - Finish date </ModalHeader>
          <ModalBody>
            <DateRangePicker
              value={this.state.dateRangeValue}
              onSelect={this.onSelectDateRange}
              singleDateRange={true}
              firstOfWeek={1}
              numberOfCalendars={2}
              selectionType="range"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              onClick={this.toggleDateRangePicker}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalWarning}
          toggle={this.toggleModalWarning}
        >
          <ModalHeader>Warning! </ModalHeader>
          <ModalBody>
            <span className="bold">
              Please check the date with the host before changing it
            </span>
          </ModalBody>
          <ModalFooter>
            <Button
              className="save-button"
              color="primary"
              onClick={this.toggleModalWarning}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(EditSession);
