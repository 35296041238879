import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';

import { Navmenudropdown } from 'components';
import { Navmenugroup } from 'components';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
//import useravatar from "assets/img/profile.jpg";
import logofull from "assets/img/logo-full.png";
import logomini from "assets/img/logo-mini.png";
import logofulldark from "assets/img/logo-full-dark.png";
import logominidark from "assets/img/logo-mini-dark.png";
import { Link, withRouter } from 'react-router-dom';
import {isAuthorized} from './../../../helpers/common.jsx'

// const storageItem = ;
const SERVERDIR = process.env.REACT_APP_API;
const IMGDIR = process.env.REACT_APP_IMGDIR;
const BASEDIR = process.env.REACT_APP_BASEDIR;

var ps;
var currentmenu = "notset";


class Sidebar extends React.Component{
    constructor(props){
        super(props);
        this.activeRoute.bind(this);
        this.state = {
          opendd: '',
          openmenu: 'none',
          profilename: 'Eric Nelson',
          profileimg: IMGDIR+'/images/profile/profile.jpg',
          profileposition: 'Web Developer',
          WCProfile: JSON.parse(localStorage.getItem('WeCodeLand_profile')),
          WCProfileId: localStorage.getItem('WeCodeLand_user')
        };
        this.handleOpendd = this.handleOpendd.bind(this);
        this.handlecurrent = this.handlecurrent.bind(this);

    }

    handlecurrent(currentmenu) {
       //console.log("handlecurrent"+currentmenu);
       if(this.state.opendd !== ""){
           currentmenu = "";
       }
    }

    handleOpendd(open) {
        currentmenu = "";
        if(this.state.opendd === open){
            this.setState({
                opendd: ''
            });
        } else {
            this.setState({
                opendd: open
            });
        }
        //currentmenu = "";
        //console.log(open + this.state.opendd);
    }


    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? ' active' : '';
    }
    componentDidMount(){
        
        if(this.props.admintype === 'general'){
          const imgContainer = this.state.WCProfile.image.container;
          const imgName = this.state.WCProfile.image.name;
          const occupation = this.state.WCProfile.profession || '';
          const fullname = (this.state.WCProfile.fullName || '')

          // http://41.231.122.14/server/api/containers/img/download/5e072755-36a5-4791-aa75-c320917ecf90.png
          const image = `${SERVERDIR}containers/${imgContainer}/download/${imgName}`;
          this.setState({
              profileposition: occupation,
              profileimg: ((imgContainer && imgName && image) || (IMGDIR + '/images/profile/profile-general.jpg')),
              profilename: fullname
          });
        } else if(this.props.admintype === 'hospital'){
            this.setState({
                profileposition: 'Surgeon',
                profileimg: IMGDIR+'/images/profile/profile-hospital.jpg',
                profilename: 'Dianna Austin'
            });
        } else if(this.props.admintype === 'university'){
            this.setState({
                profileposition: 'Professor',
                profilename: 'Henry Gibson',
                profileimg: IMGDIR+'/images/profile/profile-university.jpg'
            });
        } else if(this.props.admintype === 'crm'){
            this.setState({
                profileposition: 'Customer',
                profilename: 'Rick  Woods',
                profileimg: IMGDIR+'/images/profile/profile-crm.jpg'
            });
        } else if(this.props.admintype === 'music'){
            this.setState({
                profileposition: 'Singer',
                profilename: 'Kerry Flores',
                profileimg: IMGDIR+'/images/profile/profile-music.jpg'
            });
        } else if(this.props.admintype === 'blog'){
            this.setState({
                profileposition: 'Editor',
                profilename: 'Alice Gross',
                profileimg: IMGDIR+'/images/profile/profile-blog.jpg'
            });
        } else if(this.props.admintype === 'ecommerce'){
            this.setState({
                profileposition: 'Vendor',
                profilename: 'Jake  Daniel',
                profileimg: IMGDIR+'/images/profile/profile-ecommerce.jpg'
            });
        } else if(this.props.admintype === 'freelance'){
            this.setState({
                profileposition: 'Designer',
                profilename: 'Eric Nelson',
                profileimg: IMGDIR+'/images/profile/profile-freelance.jpg'
            });
        } else if(this.props.admintype === 'social'){
            this.setState({
                profileposition: 'Artist',
                profilename: 'Penny Taylor',
                profileimg: IMGDIR+'/images/profile/profile-social.jpg'
            });
        } else {
            this.setState({
                profileposition: 'Web Developer',
                profilename: 'Nancy Spencer',
                profileimg: IMGDIR+'/images/profile/profile-general.jpg'
            });
        }

        if(navigator.platform.indexOf('Win') > -1){
            ps = new PerfectScrollbar(this.refs.sidebar,{suppressScrollX: true, suppressScrollY: false});
        }

        //console.log(this.props.location.pathname);


    }
    componentDidUpdate(e) {
        if (e.history.location && e.history.location.state && e.history.location.state.sidebarUpdated) {
            console.log("updated");
            const WCProfile = JSON.parse(localStorage.getItem('WeCodeLand_profile'));
            const imgContainer = WCProfile.image.container;
            const imgName = WCProfile.image.name;
            // const occupation = WCProfile.job || '';
            const fullname = (WCProfile.fullName);

            // http://41.231.122.14/server/api/containers/img/download/5e072755-36a5-4791-aa75-c320917ecf90.png
            const image = `${SERVERDIR}containers/${imgContainer}/download/${imgName}`;
            e.history.location.state.sidebarUpdated = false;
            this.setState({
                WCProfile: WCProfile,
                profileimg: (image || (IMGDIR + '/images/profile/profile-general.jpg')),
                profilename: fullname
            });
        }
    }
    componentWillUnmount(){
        if(navigator.platform.indexOf('Win') > -1){
            ps.destroy();
        }
    }
    render(){
        const { WCProfileId } = this.state;

        const checkSession = (childArray, authorizedRoles, name) =>{
            console.log("authorizedRoles", authorizedRoles);
            const profile = localStorage.getItem("WeCodeLand_profile");
            let prof = JSON.parse(profile);
          
            if ((name === "Sessions") && (prof.roles.findIndex(item =>  item.name === "teacher") < 0)){
                return([
                    { path: BASEDIR + "/sessions", name: "All Sessions" },
      
                    { path: BASEDIR + "/ongoingSessions", name: "Ongoing Sessions" },
      
                    { path: BASEDIR + "/upcomingSessions", name: "Upcoming Sessions" }
                  ])
                  
            }
            else return(childArray)
        }

        const children = (child, parent) => {
            var links = [];
            if (child) {
                for (var i = 0; i < child.length; i++) {
                    links.push(
                        <li key={i}>
                            <NavLink to={child[i].path} className="nav-link" activeClassName="active">
                                <span>{child[i].name}</span>
                            </NavLink>
                        </li>
                    );
                    //console.log(child[i].parent + this.props.location.pathname + child[i].path);
                    if(this.props.location.pathname === child[i].path){
                        //console.log("match found " + child[i].parent);
                        if(currentmenu === "notset" && this.state.opendd === ""){
                            currentmenu = parent; //child[i].parent;
                        }
                    }
                    if(this.props.location.pathname === "/"){
                        currentmenu = "dashboards";
                    }
                }

                //console.log(currentmenu);
                //console.log(this.props.location.pathname);
                //console.log(parent);
                return <Nav>{links}</Nav>
            }
        }



        return (
            <div className="sidebar menubar" data-color="black">

                <div className="logo">
                    <a href={BASEDIR+"/dashboard"} className="logo-mini">
                        <div className="logo-img">
                            <img src={logomini} alt="react-logo" className="light-logo" />
                            <img src={logominidark} alt="react-logo" className="dark-logo"/>
                        </div>
                    </a>
                    <a href={BASEDIR+"/dashboard"} className="logo-full">
                            <img src={logofull} alt="react-logo" className="light-logo"/>
                            <img src={logofulldark} alt="react-logo" className="dark-logo"/>
                    </a>
                </div>

                <div className="sidebar-wrapper" ref="sidebar">
                    <Link to={{ pathname: BASEDIR + "/profile/" + WCProfileId, state: { id: WCProfileId } }}>
                        <div className="profile-info row pb-4">
                            <div className="profile-image col-4">
                                <a href="#!">
                                    <img alt="" src={this.state.profileimg} className="img-fluid avatar-image"/>
                                </a>
                            </div>
                            <div className="profile-details col-8">
                                <h3>
                                    <a href="#!">{this.state.profilename}</a>
                                    <span className="profile-status online"></span>
                                </h3>
                                <p className="profile-title">{this.state.profileposition}</p>
                            </div>
                        </div>
                    </Link>
                    <Nav className="navigation">
                        {
                            this.props.routes.map((prop,key) => {
                                if(prop.authorizedRoles && !isAuthorized(prop.authorizedRoles))
                                return null;
                                if(prop.redirect)
                                    return null;
                                if(prop.type === "child")
                                    return null;
                                if(prop.type === "navgroup")
                                    return (
                                      <Navmenugroup name={prop.name} key={key}>
                                      </Navmenugroup>
                                    );
                                if(prop.type === "dropdown" && JSON.parse(localStorage.getItem("WeCodeLand_profile")).roles.some(item =>  prop.authorizedRoles.includes(item.name)))
                                    return (

                                        <li className={(prop.parentid) + " " + (( ( (prop.parentid === currentmenu) && (prop.parentid !== "") && (prop.parentid !== "multipurpose") ) || (this.state.opendd === prop.name)) ? 'active': '') + ' nav-parent '} data-toggle="collapse" key={key}>
                                            <a to={prop.path} className="nav-link" onClick={() => this.handleOpendd(prop.name)} href="#!">
                                                        <i className={"i-"+prop.icon}></i>
                                                        <p>{prop.name}</p>
                                                        <span className="badge">{prop.badge}</span>
                                                        <span className={"arrow i-arrow-left"}></span>
                                            </a>
                                            { children(checkSession(prop.child,prop.authorizedRoles, prop.name), prop.parentid) }
                                        </li>

                                    );

                                if(prop.type === "dropdown-backup")
                                    return (
                                      <Navmenudropdown name={prop.name} icon={prop.icon} path={prop.path} badge={prop.badge} child={prop.child} key={key} openclass={this.state.opendd === prop.name ? 'activethis': ''}  onClick={() => this.handleOpendd(prop.name)}>
                                      </Navmenudropdown>
                                    );
                                return (
                                    <li className={this.activeRoute(prop.path) + ' nav-parent '} key={key} onClick={() => this.handleOpendd(prop.name)}>
                                        <NavLink to={prop.path} className="nav-link" activeClassName="active">
                                            <i className={"i-"+prop.icon}></i>
                                            <p>{prop.name}</p>
                                            <span className="badge">{prop.badge}</span>
                                        </NavLink>
                                  </li>
                                );
                            })

                        }

                    </Nav>
                </div>



            </div>
        );
    }
}

export default withRouter(Sidebar);
