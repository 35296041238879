/** @format */

// ##############################
// // // pages
// #############################
var IMGDIR = process.env.REACT_APP_IMGDIR;

const pages = [
	{
		image: IMGDIR + "/images/blog/pages/page-8.jpg",
		title: "Accomplishments",
		date: "26th Nov 2018",
		author: "Harry Thomson",
		comments: "5",
		description:
			"The modern blog evolved from the online diary where people would keep a running account of the events in their personal lives. Most such writers called themselves diarists, journalists, or journalers.",
	},
	{
		image: IMGDIR + "/images/blog/pages/page-10.jpg",
		title: "Our History",
		date: "12th Nov 2018",
		author: "Isaac Sharp",
		comments: "5",
		description:
			"Many pages provide commentary on a particular subject or topic, ranging from politics to sports. Others function as more personal online diaries, and others function more as online brand advertising of a particular individual or company.",
	},
	{
		image: IMGDIR + "/images/blog/pages/page-7.jpg",
		title: "Vision and Mission",
		date: "13th Nov 2018",
		author: "Jacob Doug",
		comments: "5",
		description:
			"A typical blog combines text, digital images, and links to other pages, web pages, and other media related to its topic. The ability of readers to leave publicly viewable comments, and interact with other commenters, is an important contribution.",
	},
	{
		image: IMGDIR + "/images/blog/pages/page-14.jpg",
		title: "About Us",
		date: "26th Nov 2018",
		author: "Dan Welch",
		comments: "5",
		description:
			"Another early blog was Wearable Wireless Webcam, an online shared diary of a person personal life combining text, digital video, and digital pictures transmitted live from a wearable computer and EyeTap device to a web site in 1994. ",
	},
	{
		image: IMGDIR + "/images/blog/pages/page-11.jpg",
		title: "Contact Us",
		date: "27th Nov 2018",
		author: "Christian Walsh",
		comments: "5",
		description:
			"This practice of semi-automated blogging with live video together with text was referred to as sousveillance, and such journals were also used as evidence in legal matters. Some early bloggers, such as The Misanthropic Bitch",
	},
	{
		image: IMGDIR + "/images/blog/pages/page-12.jpg",
		title: "Gallery",
		date: "25th Nov 2018",
		author: "James Vance",
		comments: "5",
		description:
			'The earliest instance of a commercial blog was on the first business to consumer Web site created in 1995 by Ty, Inc., which featured a blog in a section called "Online Diary". ',
	},
	{
		image: IMGDIR + "/images/blog/pages/page-3.jpg",
		title: "Our Services",
		date: "14th Nov 2018",
		author: "Gavin Smith",
		comments: "5",
		description:
			"In education, pages can be used as instructional resources. These pages are referred to as edupages. Microblogging is another type of blogging, featuring very short posts.",
	},
	{
		image: IMGDIR + "/images/blog/pages/page-1.jpg",
		title: "Pricing Plans",
		date: "16th Nov 2018",
		author: "Clarrie Stewart",
		comments: "5",
		description:
			"Before blogging became popular, digital communities took many forms including Usenet, commercial online services such as GEnie, Byte Information Exchange (BIX) and the early CompuServe, e-mail lists,[14] and Bulletin Board Systems (BBS).",
	},
];
export const InterestsList = [
	{
		name: "Cloud",
	},
	{
		name: "Web Development",
	},
	{
		name: "iOS",
	},
	{
		name: "Android",
	},
	{
		name: "Unity",
	},
	{
		name: "Digital Marketing",
	},
	{
		name: "Design",
	},
	{
		name: "UI",
	},
	{
		name: "UX",
	},
	{
		name: "Game programming",
	},
	{
		name: "VR",
	},
	{
		name: "AR",
	},
	{
		name: "Data",
	},
	{
		name: "Artificial Intelligence",
	},
	{
		name: "E-commerce",
	},
	{
		name: "Graphic Design",
	},
	{
		name: "Scrum Master",
	},
	{
		name: "Product Owner",
	},
	{
		name: "Python",
	},
	{
		name: "DevOps",
	},
	{
		name: "Project Management",
	},
	{
		name: "SEO",
	},
	{
		name: "Unity",
	},
	{
		name: "Prestashop",
	},
	{
		name: "Youtube",
	},
	{
		name: "Symfony",
	},
	{
		name: "React",
	},
	{
		name: "Angular",
	},
	{
		name: "Bootstrap",
	},
	{
		name: "Java",
	},
	{
		name: "JEE",
	},
	{
		name: "Google Apps",
	},
	{
		name: "Social Media Management",
	},
	{
		name: "Facebook",
	},
	{
		name: "Sales Strategy",
	},
	{
		name: "Business planning",
	},
	{
		name: "Soft Skills",
	},
	{
		name: "Financial Education",
	},
	{
		name: "Finance",
	},
	{
		name: "CyberSecurity",
	},
];

export const CountriesList = [ 
	"",
	"Afghanistan",
	"Albania",
	"Algeria",
	"Andorra",
	"Angola",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia",
	"Bosnia and Herzegovina",
	"Botswana",
	"Brazil",
	"Brunei",
	"Bulgaria",
	"Burkina Faso",
	"Burma",
	"Burundi",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cape Verde",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Colombia",
	"Comoros",
	"Congo, Democratic Republic",
	"Congo, Republic of the",
	"Costa Rica",
	"Cote d'Ivoire",
	"Croatia",
	"Cuba",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"East Timor",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Ethiopia",
	"Fiji",
	"Finland",
	"France",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Greece",
	"Greenland",
	"Grenada",
	"Guatemala",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran",
	"Iraq",
	"Ireland",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea, North",
	"Korea, South",
	"Kuwait",
	"Kyrgyzstan",
	"Laos",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macedonia",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Mauritania",
	"Mauritius",
	"Mexico",
	"Micronesia",
	"Moldova",
	"Mongolia",
	"Morocco",
	"Monaco",
	"Mozambique",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Norway",
	"Oman",
	"Pakistan",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Poland",
	"Portugal",
	"Qatar",
	"Romania",
	"Russia",
	"Rwanda",
	"Samoa",
	"San Marino",
	" Sao Tome",
	"Saudi Arabia",
	"Senegal",
	"Serbia and Montenegro",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Swaziland",
	"Sweden",
	"Switzerland",
	"Syria",
	"Taiwan",
	"Tajikistan",
	"Tanzania",
	"Thailand",
	"Togo",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela",
	"Vietnam",
	"Yemen",
	"Zambia",
	"Zimbabwe",
];

export { pages };
