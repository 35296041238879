import React from "react";
import { Row, Col } from "reactstrap";

import {} from "components";

import axios from "axios";
import Loader from "react-loader-spinner";
import { notify } from "helpers/common";
import NotificationAlert from "react-notification-alert";

var SERVERDIR = process.env.REACT_APP_API;
var BASEDIR = process.env.REACT_APP_BASEDIR;

class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email: "",
      password: "",
      code: "",
      verified: false,
      resetted: false,
      role: "",
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });
    const email = this.state.email.toLowerCase();
    const credentials = {
      email: email,
    };

    try {
      const result = await axios.post(
        `${SERVERDIR}Profiles/reset`,
        credentials
      );

      if (result.status === 204) {
        notify({ type: "success", message: "Email sent successfully" }, this);
        this.setState({
          resetted: true,
          loading: false,
        });

        this.props.history.push({ pathname: BASEDIR + "/login" });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      const {
        response: {
          data: {
            error: { code, message },
          },
        } = {},
      } = error;

      if (code === "LOGIN_FAILED") {
        notify(
          { type: "danger", message: "Incorrect Email or Password" },
          this
        );
      } else {
        notify(
          {
            type: "danger",
            message:
              message === "Access Denied"
                ? "You are not authorized to access the platform!"
                : message,
          },
          this
        );
      }
    } finally {
    }
  };

  handleVerification = async (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();

    const { code } = this.state;
    const credentials = {
      code: code,
    };

    try {
      const result = await axios.post(
        `${SERVERDIR}Profiles/checkResetCode`,
        credentials
      );

      if (result.status === 200) {
        const params = result.data;
        this.setState({
          verified: true,
          token: params.id,
          loading: false,
          role: result.data.profile.role,
        });
        // this.props.history.push({ pathname: BASEDIR + "/dashboard" });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      const {
        response: {
          data: {
            error: { code, message },
          },
        } = {},
      } = error;

      if (code === "LOGIN_FAILED") {
        notify(
          { type: "danger", message: "Incorrect Email or Password" },
          this
        );
      } else {
        notify(
          {
            type: "danger",
            message:
              message === "Access Denied"
                ? "You are not authorized to access the platform!"
                : message,
          },
          this
        );
      }
    } finally {
    }
  };

  handleUpdate = async (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();

    const { password, token, role } = this.state;
    const credentials = {
      newPassword: password,
    };

    try {
      const result = await axios.post(
        `${SERVERDIR}Profiles/updatePassword?access_token=${token}`,
        credentials
      );

      if (result.status === 200) {
        role === "admin"
          ? this.props.history.push({ pathname: BASEDIR + "/admin/login" })
          : this.props.history.push({ pathname: BASEDIR + "/login" });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      const {
        response: {
          data: {
            error: { code, message },
          },
        } = {},
      } = error;

      if (code === "LOGIN_FAILED") {
        notify(
          { type: "danger", message: "Incorrect Email or Password" },
          this
        );
      } else {
        notify(
          {
            type: "danger",
            message:
              message === "Access Denied"
                ? "You are not authorized to access the platform!"
                : message,
          },
          this
        );
      }
    } finally {
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  render() {
    if (this.state.loading)
      return (
        <div className="content-fluid h-100 d-flex justify-content-center align-items-center">
          <Loader
            type="ThreeDots"
            color="#D43272"
            height={80}
            width={80}
            timeout={900000}
          />
        </div>
      );

    if (this.state.verified) {
      return (
        <div>
          <div className="notification-popup">
            <NotificationAlert ref="notificationAlert" />
          </div>

          <div className="">
            <Row>
              <Col xs={12} md={12}>
                <div className="container-fluid">
                  <div className="login-wrapper row">
                    <div
                      id="login"
                      className="login loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                    >
                      <h1>
                        <a href="#!" title="update password Page" tabIndex="-1">
                          &nbsp;
                        </a>
                      </h1>
                      <div className="sign_form">
                        <form
                          name="updateform"
                          id="updateform"
                          onSubmit={this.handleUpdate}
                          method="post"
                        >
                          <p>
                            <div className="form-group">
                              <label
                                htmlFor="user_pass"
                                style={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "grey",
                                }}
                                className=""
                              >
                                New Password
                                <br />
                                <input
                                  type="password"
                                  name="password"
                                  value={this.state.password}
                                  onChange={this.handleInputChange}
                                  id="user_pass"
                                  className="input"
                                  minLength="8"
                                  maxLength="20"
                                  style={{ fontSize: "16px" }}
                                  placeholder="password"
                                  required
                                />
                              </label>
                            </div>
                          </p>

                          <input
                            type="submit"
                            name="wp-submit"
                            id="wp-submit"
                            className="btn btn-primary btn-block btn_login"
                            value="update"
                          />
                        </form>
                      </div>
                      <p
                        id="nav"
                        className="sign_notice"
                        style={{ color: "grey" }}
                      >
                        <a
                          href={`${BASEDIR}/login`}
                          title="Login"
                          style={{ fontWeight: "bold", color: "grey" }}
                        >
                          Login
                        </a>{" "}
                        |{" "}
                        <a
                          href={`${BASEDIR}/register`}
                          title="Sign Up"
                          style={{ fontWeight: "bold", color: "grey" }}
                        >
                          Sign Up
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    if (this.state.resetted) {
      return (
        <div>
          <div className="notification-popup">
            <NotificationAlert ref="notificationAlert" />
          </div>

          <div className="">
            <Row>
              <Col xs={12} md={12}>
                <div className="container-fluid">
                  <div className="login-wrapper row">
                    <div
                      id="login"
                      className="login loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                    >
                      <h1>
                        <a href="#!" title="Verification Page" tabIndex="-1">
                          &nbsp;
                        </a>
                      </h1>
                      {/*  <div className="sign_form">
                        <form
                          name="loginform"
                          id="loginform"
                          onSubmit={this.handleVerification}
                          method="post"
                        >
                          <p>
                            <div className="form-group">
                              <label
                                htmlFor="user_code"
                                style={{
                                  fontWeight: "600",
                                  fontSize: "18px",
                                  color: "grey",
                                }}
                                className=""
                              >
                                Verification Code
                                <br />
                                <br />
                                <input
                                  type="text"
                                  name="code"
                                  value={this.state.code}
                                  onChange={this.handleInputChange}
                                  id="user_code"
                                  className="form-control"
                                  minLength="4"
                                  maxLength="20"
                                  style={{ fontSize: "16px" }}
                                  placeholder="verification code"
                                  required
                                />
                              </label>
                            </div>
                          </p>

                          <input
                            type="submit"
                            name="wp-submit"
                            id="wp-submit"
                            className="btn btn-primary btn-block btn_login"
                            value="verify"
                          />
                        </form>

                        <p
                          id="nav"
                          className="text-dark"
                          style={{ color: "grey" }}
                        >
                          <a
                            href={`${BASEDIR}/login`}
                            title="Login"
                            style={{ color: "grey" }}
                          >
                            Login
                          </a>{" "}
                          |{" "}
                          <a
                            href={`${BASEDIR}/register`}
                            title="Sign Up"
                            style={{ fontWeight: "bold", color: "grey" }}
                          >
                            Sign Up
                          </a>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>

        <div className="">
          <Row>
            <Col xs={12} md={12}>
              <div className="container-fluid">
                <div className="login-wrapper row">
                  <div
                    id="login"
                    className="login loginpage offset-xl-4 offset-lg-3 offset-md-3 offset-0 col-12 col-md-6 col-xl-4"
                  >
                    <h1>
                      <a href="#!" title="Login Page" tabIndex="-1">
                        &nbsp;
                      </a>
                    </h1>
                    <div className="sign_form">
                      <form
                        name="loginform"
                        id="loginform"
                        onSubmit={this.handleSubmit}
                        method="post"
                      >
                        <p>
                          <div className="form-group">
                            <label
                              htmlFor="user_login"
                              className=""
                              style={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "grey",
                              }}
                            >
                              {" "}
                              Email
                              <br />
                              <br />
                              <input
                                type="email"
                                className="form-control"
                                id="user_login"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                placeholder="email@company.com"
                                required
                              />
                            </label>{" "}
                          </div>
                        </p>

                        <input
                          type="submit"
                          name="wp-submit"
                          id="wp-submit"
                          className="btn btn-primary btn-block btn_login"
                          value="Send Verification Email"
                        />
                      </form>

                      <p
                        id="nav"
                        className="sign_notice"
                        style={{ color: "grey" }}
                      >
                        <a
                          href={`${BASEDIR}/login`}
                          title="Login"
                          style={{ fontWeight: "bold", color: "grey" }}
                        >
                          Login
                        </a>{" "}
                        |{" "}
                        <a
                          href={`${BASEDIR}/register`}
                          title="Sign Up"
                          style={{ fontWeight: "bold", color: "grey" }}
                        >
                          Sign Up
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Reset;
