import React , {useState , useEffect } from 'react';
import NotificationAlert from "react-notification-alert";
import ReactPaginate from 'react-paginate';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row, Table } from 'reactstrap';
import ModalProfilePreview from '../../components/modals/ModalProfilePreview.jsx';
import API from "./../../helpers/api.jsx";
import Loader from "react-loader-spinner";


const BASEDIR = process.env.REACT_APP_BASEDIR;
var SERVERDIR = process.env.REACT_APP_API;

export default function AdminCreation({ match , history }) {
  const [loading, setloading] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    getRoles();
  }, []);


  

  const getRoles = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const roles = await API.get(
      `Profiles/adminsList?access_token=${accessToken}`
    );

    if (roles.status === 200) {
      console.log("roles :", roles.data);
      setRolesList(roles.data);
    } else {
      console.log("error");
    }
  };

 const addAdmin = (e) => {
    // this.setState({
    //   loading: true
    // });
    console.log("event.target", e.target);

    e.preventDefault();
      const request = {
        roleId,
        fullName,
        email,
        password,
        phone,
      };

      API.post(`Profiles/newAdmin?access_token=${localStorage.getItem("WeCodeLand_jwt")}`, request)
        .then((res) => {
          setloading(false);
          if (res.status === 200) {
            console.log("SUBMITTED SUCCESSFULLY");
            history.push(BASEDIR + "/admin/membersManagement");
          }
        })
        .catch((error) => {
          // this.setState({
          //   loading: false
          // });
          console.log("error:", error);
          const {
            response: {
              data: {
                error: { message },
              },
            } = {},
          } = error;
        });
    
  };

 return (
   <div>
     <div className="content">
       <Row>
         <Col xs={12} md={12}>
           <div className="page-title">
             <div className="float-left">
               <h1 className="title" style={{ textTransform: "none" }}>
                 New Admin
               </h1>
             </div>
           </div>

           <div className="row margin-0">
             <div className="col-12">
               <section className="box ">
                 <header className="panel_header"></header>
                 <div className="content-body">
                   <form id="myForm" onSubmit={addAdmin} method="post">
                     <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                       <div className="row">
                         <div className="form-group col-md-6">
                           <label
                             htmlFor="imputRoleId"
                             style={{ fontWeight: "bold" }}
                           >
                             Role
                           </label>
                           <select
                             id="imputRoleId"
                             name="roleId"
                             className="form-control"
                             style={{ borderRadius: "4px" }}
                             onChange={(e) => setRoleId(e.target.value)}
                             value={roleId}
                             placeholder="Role"
                             required
                           >
                             {rolesList?.map(({ id, name }) => {
                               return (
                                 <option value={id} key={id}>
                                   {name}
                                 </option>
                               );
                             })}
                           </select>
                         </div>
                         <div className="form-group col-md-6">
                           <label
                             htmlFor="imputFullName"
                             style={{ fontWeight: "bold" }}
                           >
                             FullName
                           </label>
                           <input
                             type="text"
                             name="fullName"
                             maxLength="400"
                             onChange={(e) => setFullName(e.target.value)}
                             value={fullName}
                             className="form-control"
                             id="imputFullName"
                             placeholder="Created by"
                             style={{ borderRadius: "4px" }}
                             required
                           />
                         </div>
                       </div>
                       <div className="row">
                         <div className="form-group col-md-6">
                           <label
                             htmlFor="imputEmail"
                             style={{ fontWeight: "bold" }}
                           >
                             Email
                           </label>
                           <input
                             type="email"
                             className="form-control"
                             id="user_email"
                             name="email"
                             value={email}
                             onChange={(e) => setEmail(e.target.value)}
                             required
                           />
                         </div>
                         <div className="form-group col-md-6">
                           <label
                             htmlFor="imputPassword"
                             style={{ fontWeight: "bold" }}
                           >
                             Passsword
                           </label>
                           <input
                             type="password"
                             name="password"
                             maxLength="30"
                             onChange={(e) => setPassword(e.target.value)}
                             value={password}
                             className="form-control"
                             id="imputPassword"
                             placeholder="Password"
                             style={{ borderRadius: "4px" }}
                             required
                           />
                         </div>
                       </div>
                       <div className="row">
                         <div className="form-group col-md-6">
                           <label
                             htmlFor="imputPhone"
                             style={{ fontWeight: "bold" }}
                           >
                             Phone
                           </label>
                           <input
                             type="phone"
                             className="form-control"
                             id="user_phone"
                             name="phone"
                             value={phone}
                             onChange={(e) => setPhone(e.target.value)}
                             required
                           />
                         </div>
                       </div>
                       <div className="row" style={{ justifyContent: "end" }}>
                         <Button
                           form="myForm"
                           key="submit"
                           htmltype="submit"
                           style={{ borderRadius: "8px" }}
                           color="primary"
                         >
                           Save
                         </Button>
                       </div>
                     </div>
                   </form>
                 </div>
               </section>
             </div>
           </div>
         </Col>
       </Row>
     </div>
   </div>
 );

}