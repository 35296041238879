import React from "react";
import { Row, Col } from "reactstrap";

import { faGoogle, faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import Facebook from "../../../components/facebookLogin/Facebook.jsx";
import GoogleBtn from "../../../components/googleLogin/GoogleBtn.jsx";

import Loader from "react-loader-spinner";
import { notify } from "../../../helpers/common";
import NotificationAlert from "react-notification-alert";
import API from "../../../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import loginPic from "assets/img/login_pic.png";

var BASEDIR = process.env.REACT_APP_BASEDIR;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email: "",
      password: "",
    };
  }
  componentWillMount() {
    const jwt = localStorage.getItem("WeCodeLand_jwt");
    const profile = localStorage.getItem("WeCodeLand_profile");
    let prof = JSON.parse(profile);
    if (jwt && prof && prof.roles && prof.roles.length > 0) {
      console.log(
        "TESTT",
        prof &&
          prof.roles.some((item) => item.name?.toUpperCase()?.includes("ADMIN"))
      );
      prof &&
      prof.roles.some((item) => item.name?.toUpperCase()?.includes("ADMIN"))
        ? this.props.history.push({ pathname: BASEDIR + "/admin/dashboard" })
        : this.props.history.push({ pathname: BASEDIR + "/dashboard" });
    }
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    const { password } = this.state;
    const email = this.state.email.toLowerCase();

    try {
      const result = await API.post(`Profiles/specialLogin`, null, {
        params: {
          email: email,
          password: password,
        },
      });

      if (result.status === 200) {
        const params = result.data;
        localStorage.setItem("WeCodeLand_jwt", params.id);
        localStorage.setItem("WeCodeLand_user", params.userId);
        localStorage.setItem(
          "WeCodeLand_profile",
          JSON.stringify(params.userData)
        );
        this.setState({
          loading: false,
        });
        params.userData &&
        params.userData.roles &&
        params.userData.roles.some((item) =>
          item.name?.toUpperCase()?.includes("ADMIN")
        )
          ? this.props.history.push({ pathname: BASEDIR + "/admin/dashboard" })
          : this.props.history.push({ pathname: BASEDIR + "/dashboard" });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      const {
        response: {
          data: {
            error: { code, message },
          },
        } = {},
      } = error;
      console.log("code: ", code);
      if (code === "LOGIN_FAILED_EMAIL_NOT_VERIFIED") {
        notify(
          {
            type: "danger",
            message: "Login failed as the email has not been verified",
          },
          this
        );
      } else if (code === "LOGIN_FAILED") {
        notify(
          { type: "danger", message: "Incorrect email or password" },
          this
        );
      } else {
        notify(
          {
            type: "danger",
            message:
              message === "Access Denied"
                ? "You are not authorized to access the platform!"
                : message,
          },
          this
        );
      }
      console.log("error: ", error);
      console.log("executed");
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  render() {
    if (this.state.loading)
      return (
        <div className="content-fluid h-100 d-flex justify-content-center align-items-center">
          <Loader
            type="ThreeDots"
            color="#D43272"
            height={80}
            width={80}
            timeout={900000}
          />
        </div>
      );

    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>

        <div className="sign_container">
          <Row>
            <Col md={5} sm={0} className="d-none d-md-block">
              <img src={loginPic} alt="banner" className="login_picture" />
            </Col>
            <Col md={7} sm={12}>
              <Row>
                <Col md={8} sm={{ size: 10, offset: 1 }}>
                  <div className="sign_content">
                    <h4 className="sign_title">Sign In to the platform</h4>

                    <div className="sign_form">
                      <form
                        name="loginform"
                        id="loginform"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="form-group">
                          <label htmlFor="user_login">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="user_login"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            placeholder="email@company.com"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="user_pass">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="user_pass"
                            placeholder="Password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            minLength="8"
                            maxLength="20"
                            placeholder="password"
                            required
                          />
                        </div>

                        <input
                          type="submit"
                          name="wp-submit"
                          id="wp-submit"
                          className="btn btn-primary btn-block btn_login"
                          value="Login"
                        />
                      </form>
                      <p id="nav" className="sign_notice">
                        {/*  <a href="reset" title="Password Lost and Found">Forgot password?</a> |  */}
                        <a
                          href={`${BASEDIR}/register`}
                          title="Sign Up"
                          className="bold"
                        >
                          Sign Up
                        </a>
                      </p>

                      <p>
                        <h5 className="text_through">
                          <span>Or</span>
                        </h5>
                      </p>

                      <div>
                        <GoogleBtn />
                        {/* <button className="btn btn-primary btn-block btn_login google_btn"><FontAwesomeIcon className="btn_brand_ico" icon={faGoogle} />Sign in with Google<span className="opacity-0">ok</span></button>
                         */}
                        {/* <button className="btn btn-primary btn-block btn_login fb_btn"> <FontAwesomeIcon className="btn_brand_ico"  icon={faFacebookSquare} /> Sign in with Facebook</button> */}
                        <Facebook />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Login;
